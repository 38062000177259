import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})

export class AppFooterComponent {
  currentYear: string;

  constructor() {
    const currentYear = moment(new Date()).format('YYYY');
    this.currentYear = currentYear;
  }

  // Opens the Privacy Notice Content in Modal
  openNotice() {
    window.open('https://www.lifeline.com/privacy-policy/', '_blank');
  }
}
