import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conversion-stepper-control',
  templateUrl: './conversion-stepper-control.component.html',
  styleUrls: ['./conversion-stepper-control.component.css']
})
export class ConversionStepperControlComponent implements AfterViewInit {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute) { }

  ngAfterViewInit(): void {
    this.route.url.subscribe(urlSegments => {
      if (urlSegments.length > 0 && urlSegments[0].path === 'inprogress') {
        this.stepper.selectedIndex = 1;
        this.cdr.detectChanges();
      } else {
        this.stepper.reset();
        this.cdr.detectChanges();
      }
    });
  }

  goToNextForm(): void {
    this.stepper.next();
  }

  goToPreviousForm(): void {
    this.stepper.previous();
  }

  startOver(): void {
    this.stepper.reset();
  }
}
