import { FormGroup } from '@angular/forms';

export const matchField = (controlName: string, matchingControlName: string) => {
  const getNumber = (value: string) => value.replace(/\D/g, '');

  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    // Return if another validator has already found an error on matchingControl
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    let isValid = true;

    if ('phoneMatch' === matchingControlName) {
      isValid = getNumber(control.value) === getNumber(matchingControl.value);
    } else {
      isValid = control.value === matchingControl.value;
    }

    if (isValid) {
      matchingControl.setErrors(null);
    } else {
      matchingControl.setErrors({ mustMatch: true });
    }
  };
};
