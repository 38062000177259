import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCaptchaModule } from 'ngx-captcha';

import {
  VaisStepperControlComponent, VaisCouponVerificationComponent,
  VaisSubHeaderComponent, VaisProductSelectionComponent, VaisMemberInformationComponent,
  VaisEmergencyContactInfoComponent, VaisReviewOrderComponent, VaisFormModalComponent, VaisSuccessPageComponent
} from './components';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared';
import { VaisProviderPhoneComponent } from './components/vais-provider-phone/vais-provider-phone.component';
import { MaskSensitivePipe } from '../shared/pipes/mask-sensitive.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DateParserFormatterService } from './services/date-parser-formatter.service';

@NgModule({
  declarations: [
    VaisStepperControlComponent,
    VaisCouponVerificationComponent,
    VaisSubHeaderComponent,
    VaisProductSelectionComponent,
    VaisMemberInformationComponent,
    VaisEmergencyContactInfoComponent,
    VaisReviewOrderComponent,
    VaisFormModalComponent,
    VaisSuccessPageComponent,
    VaisProviderPhoneComponent,
    MaskSensitivePipe,
    ProgressBarComponent
  ],
  imports: [
    CommonModule, MaterialModule, NgbModule, ReactiveFormsModule, FormsModule,
    SharedModule, NgSelectModule,
    NgxCaptchaModule
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateParserFormatterService},
    TitleCasePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    VaisStepperControlComponent, VaisSubHeaderComponent, VaisCouponVerificationComponent,
    VaisProductSelectionComponent, VaisMemberInformationComponent, VaisEmergencyContactInfoComponent,
    VaisFormModalComponent, VaisSuccessPageComponent, VaisProviderPhoneComponent
  ],
  entryComponents: [VaisFormModalComponent]
})

export class VaisEnrollmentFormsModule { }
