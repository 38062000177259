import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { EligibilityResponse } from '../../model/eligibility.model';
import { SearchService } from '../../service/search.service';
import { ModalComponent } from 'src/app/shared/components';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-search-field',
  templateUrl: './searchfield.component.html',
  styleUrls: ['./searchfield.component.css']
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  criteria: string;
  searchval: string;
  subscription: Subscription;
  showError = false;
  errorMessage: string;
  errorType: string;
  loading = false;

  constructor(
    private searchService: SearchService,
    private authService: AuthService,
    private ngbModal: NgbModal
  ) {
    this.clear();
  }

  ngOnInit() {
    this.subscription = this.searchService.customersChanged$
      .subscribe(
        (customerList: EligibilityResponse[]) => {
          if (customerList == null) {
            this.showError = true;
            this.errorMessage = 'No Results Found';
            this.errorType = 'info';
          } else {
            this.showError = false;
          }
        }
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  search() {
    this.loading = true;
    this.showError = false;
    this.errorMessage = '';
    this.errorType = '';
    const phregex = /^\d{10}$/g;
    const phregexfound = this.searchval.match(phregex);

    if (!this.searchval) {
      this.showError = true;
      this.errorMessage = 'Enter a value to search';
      this.errorType = 'error';
    } else if (this.criteria === 'phone' && !phregexfound) {
      this.showError = true;
      this.errorMessage = 'Enter phone number in the standard format';
      this.errorType = 'error';
    } else {
      this.searchService.getCustomerData(this.criteria, this.searchval);
      this.authService.isLoggedIn$().pipe(take(1)).subscribe((isLoggedIn) => {
        if (!isLoggedIn) {
          const ngbModalRef = this.ngbModal.open(ModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
          ngbModalRef.componentInstance.modalTitle = 'Logged out';
          ngbModalRef.componentInstance.modalContent = 'Your session has timed out and you must log in again';
          ngbModalRef.componentInstance.showFooter = true;
          ngbModalRef.result.then(() => {
            this.authService.redirectToLogin();
          }, () => {
            this.authService.redirectToLogin();
          });
        }
      });
    }

    this.loading = false;
  }

  clear() {
    this.searchService.clearCustomerData();
    this.criteria = 'mbiMemberNumber';
    this.searchval = '';
    this.showError = false;
    this.errorMessage = '';
  }
}
