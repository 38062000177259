import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  @Input() cardImage: string;
  @Input() cardTitle: string;
  @Input() cardSubTitle: string;

  constructor() { }

  ngOnInit() { }
}
