import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormsService } from '../../services';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.css']
})
export class SuccessPageComponent implements OnInit, OnDestroy {
  memberName: string;
  phoneno: string;
  isRepLoggedIn = false;
  isSubmitSuccess: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  emailForm: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formsService: FormsService,
    private camelCase: TitleCasePipe,
    private emailFormBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private searchService: SearchService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isRepLoggedIn => {
      this.isRepLoggedIn = isRepLoggedIn;
    });

    this.formsService.formMemberInfo$.subscribe(successResponse => {
      if (successResponse && Object.keys(successResponse).length > 0 && successResponse.controls.firstName) {
        this.memberName = this.camelCase.transform(successResponse.controls.firstName.value);
      }
    });

    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe(careDetails => {
      if (careDetails) {
        this.phoneno = careDetails.phoneno + ' ' + careDetails.ext;
      }
    });

    this.emailForm = this.emailFormBuilder.group({
      email: ['',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[A-Za-z0-9_.%+-]{1,}@[A-Za-z0-9-]{1,}[.]{1}[A-Za-z.]{2,}$/)
        ]
      ],
    });
    this.isSubmitting = false;
    this.isSubmitted = false;
    this.isSubmitSuccess = false;
  }

  sendEmail() {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
    } else {
      this.isSubmitting = true;
      const emailAddress = this.emailForm.get('email').value;
      this.formsService.sendConfirmationEmail(emailAddress).subscribe(
        () => {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.isSubmitSuccess = true;
        }, () => {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.isSubmitSuccess = false;
        });
    }
  }

  backToForm() {
    this.isSubmitted = false;
    this.isSubmitSuccess = false;
    this.isSubmitting = false;
  }

  referredFromSearch(): boolean {
    return this.searchService.getSelectedCustomer() ? true : false;
  }

  searchAgain(): void {
    this.router.navigateByUrl('/eligibility-search');
  }
}
