import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const yearAndMonthIsFutureValidator = (monthControlName: string, yearControlName: string): ValidatorFn =>
    (formGroup: AbstractControl): ValidationErrors | null => {
        const monthControl = formGroup.get(monthControlName);
        const yearControl = formGroup.get(yearControlName);

        if (!monthControl || !yearControl || !monthControl.value || !yearControl.value) {
            return null;
        }

        if (isNaN(monthControl.value) || isNaN(yearControl.value) ||
            new Date(yearControl.value, monthControl.value - 1, 1) <= new Date()) {
            return { yearMonthRange: { error: `${monthControl.value}-${yearControl.value} is not a future date` } };
        } else {
            return null;
        }
    };
