import { ComponentCanDeactivateComponent } from './component-can-deactivate';
import { NgForm } from '@angular/forms';

export abstract class FormCanDeactivate extends ComponentCanDeactivateComponent {
  abstract get form(): NgForm;

  // Boolean value is returned when the form is not submitted or valid
  canDeactivate(): boolean {
    return false;
  }
}
