import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const luhnCheck = (cardNumber: string): boolean => {
    const digits = cardNumber.replace(/\D/g, '').split('').map(Number);
    let sum = 0;
    let isSecond = false;
    for (let i = digits.length - 1; i >= 0; i--) {
        let digit = digits[i];
        if (isSecond) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
        isSecond = !isSecond;
    }
    return sum % 10 === 0;
};

const isSpecificCard = (cardNumber: string, acceptableStartDigits: number[], acceptableLengths: number[]): boolean =>
    /^\d+$/.test(cardNumber) &&
    acceptableStartDigits.includes(Number(cardNumber.charAt(0))) &&
    acceptableLengths.includes(cardNumber.length) &&
    luhnCheck(cardNumber);

const isVisa = (cardNumber: string): boolean => {
    const acceptableStartDigits = [4];
    const acceptableLengths = [13, 16, 19];
    return isSpecificCard(cardNumber, acceptableStartDigits, acceptableLengths);
};

const isMastercard = (cardNumber: string): boolean => {
    const acceptableStartDigits = [2, 5];
    const acceptableLengths = [16];
    return isSpecificCard(cardNumber, acceptableStartDigits, acceptableLengths);
};

const isAmEx = (cardNumber: string): boolean => {
    const acceptableStartDigits = [3];
    const acceptableLengths = [15];
    return isSpecificCard(cardNumber, acceptableStartDigits, acceptableLengths);
};

const isDiscover = (cardNumber: string): boolean => {
    const acceptableStartDigits = [6];
    const acceptableLengths = [16, 17, 18, 19];
    return isSpecificCard(cardNumber, acceptableStartDigits, acceptableLengths);
};

export const creditCardValidator = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null =>
    control.value && (isVisa(control.value) || isMastercard(control.value) || isAmEx(control.value) || isDiscover(control.value)) ?
        null : { creditcardvalidator: 'Failed validation' };
