import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const configDateValidator = (monthControlName: string, yearControlName: string, configExpiration: Date): ValidatorFn =>
    (formGroup: AbstractControl): ValidationErrors | null => {
        const monthControl = formGroup.get(monthControlName);
        const yearControl = formGroup.get(yearControlName);

        if (!monthControl || !yearControl || !monthControl.value || !yearControl.value || !configExpiration) {
            return null;
        }

        if (isNaN(monthControl.value) || isNaN(yearControl.value) ||
            new Date(yearControl.value, monthControl.value - 1, 1) < configExpiration) {
            return { configDateRange: { error: `${monthControl.value}-${yearControl.value} is an acceptable date` } };
        } else {
            return null;
        }
    };
