import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { VaisFormsService } from '../../services';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-vais-success-page',
  templateUrl: './vais-success-page.component.html',
  styleUrls: ['./vais-success-page.component.css']
})
export class VaisSuccessPageComponent implements OnInit {
  memberName = 'New member';
  isRepLoggedIn = false;
  didEmailSend: boolean;

  constructor(
    private vaisFormsService: VaisFormsService,
    private camelCase: TitleCasePipe,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService
  ) { }

  get isReferredFromSearch(): boolean {
    return this.searchService.getSelectedCustomer() ? true : false;
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isRepLoggedIn => {
      this.isRepLoggedIn = isRepLoggedIn;
    });

    const lastSubmittedApplicant = this.vaisFormsService.lastSubmittedApplicant;

    if (lastSubmittedApplicant.email === '') {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    if (lastSubmittedApplicant.firstName) {
      this.memberName = this.camelCase.transform(`${lastSubmittedApplicant.firstName} ${lastSubmittedApplicant.lastName}`);
    }

    this.vaisFormsService.sendConfirmationEmail().subscribe();
  }

  searchAgain(): void {
    this.router.navigateByUrl('/eligibility-search');
  }
}
