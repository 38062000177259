import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mock-input-element',
  templateUrl: './mock-input-element-prevent-copy.component.html',
  styleUrls: ['./mock-input-element-prevent-copy.component.css']
})
export class MockInputElementPreventCopyComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
