import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, NgForm, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import languages from '../../../../assets/json/languages.json';
import phoneTypes from '../../../../assets/json/phonetype.json';
import relations from '../../../../assets/json/relations.json';
import roles from '../../../../assets/json/roles.json';

import { FormsService } from '../../services';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { AuthService } from 'src/app/shared/services/auth.service';

const MAX_CAREGIVER_FORMS = 3;

@Component({
  selector: 'app-caregiver-info',
  templateUrl: './caregiver-info.component.html',
  styleUrls: ['./caregiver-info.component.css']
})

export class CaregiverInfoComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @Output() previousEvent = new EventEmitter<number>();
  @ViewChild('formFour', { static: false }) form: NgForm;

  caregiverForm: FormGroup;
  languages: any[];
  relations: any[];
  phoneTypes: any[];
  roles: any[];
  showSaveButton: boolean;
  disableAwayService = false;
  enableNextButton: string;
  matchValidation = false;
  isResponder: boolean;
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Away Service Contact is required if OTG+ Away Service is selected
  isAwayContactRequired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Caregiver form is optional if a rep is logged in and Away Service Contact is not required
  isCaregiverOptional$: Observable<boolean>;
  math = Math;

  careModalSubs: Subscription; giverSubs: Subscription;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formsService: FormsService,
    private careModal: NgbModal,
    private authService: AuthService
  ) {
    super();
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe(careDetails => {
      if (careDetails) {
        this.disableAwayService = careDetails.disableAwayService;
      }
    });
  }

  ngOnInit() {
    this.authService.isLoggedIn$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoggedIn => {
        this.isLoggedIn$.next(isLoggedIn);
      });

    this.caregiverForm = new FormGroup({
      details: new FormArray([]),
    });

    // Modal Save Button
    this.careModalSubs = this.formsService.showSaveButton$.subscribe(showSaveButton => {
      this.showSaveButton = showSaveButton;
    });

    // Modal Persist Values
    if (this.showSaveButton) {
      this.giverSubs = this.formsService.formCaregiverInfo$.subscribe(
        (caregiverModalResponse: FormGroup) => {
          if (caregiverModalResponse) {
            this.caregiverForm.controls.details = caregiverModalResponse.controls.details;
          }
        });
    }

    // Care caregiver Info Form Persist Values
    if (this.formsService.caregiverDetails) {
      this.caregiverForm = this.formsService.caregiverDetails;
    }

    this.languages = languages;
    this.relations = relations;
    this.phoneTypes = phoneTypes;
    this.roles = [...roles];

    this.formsService.formProductInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe(productInfo => {
        if (productInfo && productInfo.controls && Object.keys(productInfo.controls).length > 0) {
          const isOtgPlusSelected = productInfo.controls.otgPlusSelected.value;
          const isAwayServiceSelected = productInfo.controls.otgAwayServiceSelected.value;
          if (isOtgPlusSelected && isAwayServiceSelected) {
            this.isAwayContactRequired$.next(true);
          } else {
            this.isAwayContactRequired$.next(false);
          }
        }
      });

    this.isAwayContactRequired$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isAwayContactRequired => {
        // Remove Away Service Contacts if not required. Update and validate Away Service Contact in roles drop down.
        if (isAwayContactRequired && !this.disableAwayService) {
          this.roles = [{ name: 'Away Service Contact', id: 3 }, ...roles];
        } else {
          this.roles = [...roles];
          this.deleteAwayContacts();
        }
        this.checkAwayContactRole();
      });

    this.isCaregiverOptional$ = combineLatest(
      this.isLoggedIn$,
      this.isAwayContactRequired$
    ).pipe(
      map(([isLoggedIn, isAwayContactRequired]) => isLoggedIn && !isAwayContactRequired)
    );

    this.isCaregiverOptional$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isCaregiverOptional => {
        if (isCaregiverOptional) {
          this.clearCaregiverFormIfUntouched();
        } else {
          // Create a first caregiver form if caregiver is required
          const caregiverForms = this.getCaregiverForms(this.caregiverForm);
          if (caregiverForms && caregiverForms.length === 0) {
            this.addCaregiverForm();
          }
        }
      });

    this.formsService.validateCaregiverForm$$
      .subscribe(validateCaregiverForm => {
        if (validateCaregiverForm) {
          this.validateForm();
        }
      });

    this.caregiverForm.statusChanges.subscribe((status) => {
      this.formsService.updateCaregiverFormValid(status === 'VALID');
    });

    this.isResponder = true;
    this.checkCaregiverSelections();
  }

  checkCaregiverSelections() {
    const caregiversFormGroup = this.caregiverForm.controls.details as FormGroup;
    if (!caregiversFormGroup.controls[0]) {
      return;
    }

    const firstCaregiverFormGroup = caregiversFormGroup.controls[0] as FormGroup;
    if (!firstCaregiverFormGroup) {
      return;
    }

    if (firstCaregiverFormGroup.controls.language.value === null) {
      firstCaregiverFormGroup.controls.language.setValue('');
    }

    if (firstCaregiverFormGroup.controls.role.value === null) {
      firstCaregiverFormGroup.controls.role.setValue('');
    }

    if (firstCaregiverFormGroup.controls.relationship.value === null) {
      firstCaregiverFormGroup.controls.relationship.setValue('');
    }

    const firstCaregiverPhoneDetailsGroup = firstCaregiverFormGroup.controls.phoneDetails as FormArray;
    const firstCaregiverFirstPhoneDetailsGroup = firstCaregiverPhoneDetailsGroup.controls[0] as FormGroup;

    if (firstCaregiverFirstPhoneDetailsGroup.controls.carePhoneType.value === null) {
      firstCaregiverFirstPhoneDetailsGroup.controls.carePhoneType.setValue('');
    }
  }

  goToFormReview() {
    const isFormNext = this.checkRole();
    if (!this.caregiverForm.valid) {
      this.caregiverForm.markAllAsTouched();
    } else {
      if (isFormNext) {
        this.formsService.formCaregiverInfo$.next(this.caregiverForm);
        this.nextEvent.emit();
      }
    }
  }

  goToFormMemberInfo() {
    this.previousEvent.emit(2);
  }

  saveCaregiverForm() {
    const isFormSave = this.checkRole();
    if (this.caregiverForm.controls.details.status === 'INVALID') {
      this.caregiverForm.markAllAsTouched();
    } else {
      if (isFormSave) {
        this.formsService.formCaregiverInfo$.next(this.caregiverForm);
        this.careModal.dismissAll();
      }
    }
  }

  initDetails() {
    return new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.minLength(3),
        Validators.maxLength(40)
      ]),
      middleName: new FormControl('', [
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.maxLength(40)
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.minLength(3),
        Validators.maxLength(40)
      ]),
      phoneDetails: new FormArray([
        this.initPhoneDetails(),
      ]),
      email: new FormControl('', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]),
      emailMatch: new FormControl(''),
      relationship: new FormControl('', Validators.required),
      role: new FormControl([], Validators.required),
      language: new FormControl('', Validators.required)
    }, [
      this.emailMatchValidator,
      this.awayContactMobileValidator,
      this.awayContactEmailValidator
    ]);
  }

  emailMatchValidator = (fg: FormGroup) =>
    fg.get('email').value === fg.get('emailMatch').value ? null : { mismatch: true };

  /**
   * @method: awayContactMobileValidator
   * @returns: { any } an error object if form is invalid else return null
   * @description: Check if Away Service Contacts in the form contain at least one mobile number.
   */
  awayContactMobileValidator = (fg: FormGroup) => {
    const isAwayRoleSelected = fg.get('role').value && fg.get('role').value.includes('Away Service Contact');
    const hasCell = fg.get('phoneDetails').value.some(field => field.carePhoneType === 'Cell');
    if (isAwayRoleSelected && !hasCell) {
      return { mobileRequired: true };
    } else {
      return null;
    }
  };

  /**
   * @method: awayContactEmailValidator
   * @returns: { any } an error object if form is invalid else return null
   * @description: Check if Away Service Contacts in the form contain an email address.
   */
  awayContactEmailValidator = (fg: FormGroup) => {
    const isAwayRoleSelected = fg.get('role').value && fg.get('role').value.includes('Away Service Contact');
    const hasEmail = !!fg.get('email').value;
    if (isAwayRoleSelected && !hasEmail) {
      return { emailRequired: true };
    } else {
      return null;
    }
  };

  initPhoneDetails() {
    return new FormGroup({
      carePhone: new FormControl('', [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]),
      carePhoneType: new FormControl('', Validators.required)
    });
  }

  getCaregiverForms(formCaregiver) {
    return formCaregiver && formCaregiver.controls && formCaregiver.controls.details && formCaregiver.controls.details.controls;
  }

  getPhoneDetails(formPhone) {
    return formPhone.controls.phoneDetails.controls;
  }

  /**
   * @returns: true if selected roles are valid, else return false
   * @description: Validates if form contains Away Service Contact if required
   *  and contains Responder if rep is not logged in.
   */
  checkRole(): boolean {
    let roleRespond = true;
    if (!this.isLoggedIn$.value) {
      roleRespond = this.caregiverForm.controls.details.value.some(field => field && field.role && field.role.includes('Responder'));
    }
    this.isResponder = roleRespond;
    return this.isResponder && this.checkAwayContactRole();
  }

  /**
   * @returns: true if form Away Service Contact role selection is valid
   * @description: Validates if form contains Away Service Contact if required
   */
  checkAwayContactRole(): boolean {
    const detailsForm = this.caregiverForm.controls.details;
    const hasAwayContact = detailsForm.value.some(field => field.role.includes('Away Service Contact'));
    if (this.isAwayContactRequired$.value && !hasAwayContact) {
      setTimeout(() => {
        detailsForm.setErrors({ awayContactRequired: true });
      }, 0);
      return false;
    } else {
      const error = 'awayContactRequired';
      if (detailsForm.hasError(error)) {
        delete detailsForm.errors[error];
      }
      return true;
    }
  }

  // If the responder is already selected set to true do not show the error
  roleSelection() {
    if (this.caregiverForm.controls.details.value !== null) {
      this.checkRole();
    }
  }

  addCaregiverForm() {
    const caregiverForms = this.getCaregiverForms(this.caregiverForm);
    if (caregiverForms) {
      const numCaregiverForms = caregiverForms.length;
      if (numCaregiverForms < MAX_CAREGIVER_FORMS) {
        const control = this.caregiverForm.get('details') as FormArray;
        control.push(this.initDetails());
      }
    }
  }

  removeCaregiverForm(i: number) {
    const control = this.caregiverForm.get('details') as FormArray;
    control.removeAt(i);
  }

  clearCaregiverFormIfUntouched() {
    const control = this.caregiverForm.get('details') as FormArray;
    if (!control.touched && control.length > 0) {
      control.clear();
    }
  }

  addPhoneForm(i: number) {
    const control = this.caregiverForm.get(['details', i, 'phoneDetails']) as FormArray;
    control.push(this.initPhoneDetails());
  }

  removePhoneForm(i: number, j: number) {
    const control = this.caregiverForm.get(['details', i, 'phoneDetails']) as FormArray;
    control.removeAt(j);
  }

  ngOnDestroy() {
    this.formsService.caregiverDetails = this.caregiverForm;
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Removes caregiver forms which only have an Away Service Contact Role.
   * Else removes Away Service Contact role from roles if caregiver has multiple roles.
   */
  deleteAwayContacts() {
    const caregiverForms = this.caregiverForm.get('details') as FormArray;
    for (let i = caregiverForms.value.length - 1; i >= 0; i--) {
      const currentRoles = caregiverForms.value[i].role;
      if (currentRoles instanceof Array) {
        const form = caregiverForms.controls[i] as FormGroup;
        const nonAwayContactRoles = currentRoles.filter(role => role !== 'Away Service Contact');
        if (nonAwayContactRoles.length > 0) {
          form.controls.role.setValue(nonAwayContactRoles);
        } else {
          caregiverForms.removeAt(i);
        }
      }
    }
    this.formsService.formCaregiverInfo$.next(this.caregiverForm);
  }

  validateForm() {
    this.caregiverForm.markAllAsTouched();
  }

  canAddMoreCaregivers(): boolean {
    const caregiverForms = this.getCaregiverForms;
    return caregiverForms && this.getCaregiverForms(this.caregiverForm).length < MAX_CAREGIVER_FORMS;
  }
}
