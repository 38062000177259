import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PortalsService {
  portals: {name: string; url: string}[] = [
    {
      name: 'Eligibility Search',
      url: 'eligibility-search'
    },
    {
      name: 'UHC (Convert)',
      url: 'uhc/convert'
    },
    {
      name: 'UHC (Enroll)',
      url: 'uhc'
    },
    {
      name: 'UHC (VAIS)',
      url: 'vais/uhc'
    },
    {
      name: 'UHC Retiree',
      url: 'uhcgr'
    },
    {
      name: 'UHC Retiree (VAIS)',
      url: 'vais/uhcgr'
    },
    {
      name: 'Humana',
      url: 'humana'
    },
    {
      name: 'Humana Group Medicare Advantage',
      url: 'humanagr'
    }
  ];

  constructor() { }
}
