import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-vais-stepper-control',
  templateUrl: './vais-stepper-control.component.html',
  styleUrls: ['./vais-stepper-control.component.css']
})
export class VaisStepperControlComponent implements OnInit {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  constructor() {
  }

  ngOnInit(): void {
  }
}
