import { Injectable } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DateParserFormatterService extends NgbDateParserFormatter {
  // Format: mm/dd/yyyy
  format(date: NgbDate): string {
    if (date) {
      const day = ('0' + date.day).slice(-2);
      const month = ('0' + date.month).slice(-2);
      const year = date.year;
      return `${month}/${day}/${year}`;
    }
    return '';
  }

  parse(value: string): NgbDate | null {
    if (value) {
      const parts = value.split('/');
      if (parts.length === 3) {
        const month = parseInt(parts[0], 10);
        const day = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
        return new NgbDate(year, month, day);
      }
    }
    return null;
  }
}
