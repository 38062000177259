import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of, Subject, Subscription, TimeoutError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import gender from '../../../../assets/json/gender.json';
import languages from '../../../../assets/json/languages.json';
import states from '../../../../assets/json/states.json';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormsService } from '../../services';
import { matchField } from '../../../validators/match-field.validator';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { AwsRumFunctions } from 'src/app/shared/aws-rum-functions.service';

@Component({
  selector: 'app-member-information',
  templateUrl: './member-information.component.html',
  styleUrls: ['./member-information.component.css']
})

export class MemberInformationComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @Output() previousEvent = new EventEmitter<number>();
  @ViewChild('formThree', { static: false }) form: NgForm;

  addresses = [{
    text: 'Same as address above',
    value: false
  }, {
    text: 'Different shipping address',
    value: true
  }];

  memberForm: FormGroup;
  startFormDate: Date; // DOB Start Date
  shippingForm: FormGroup;
  languages: any[];
  states: any[];
  gender: any[];
  shippingStates: any[];
  showSaveButton: boolean; // Shows save Button for form modal
  isDifferentShipAddressSelected: boolean;
  memberFormAddressOptions$: Observable<string[]>;
  memberAddressData: any;
  shippingAddressOptions$: Observable<string[]>;
  shippingAddressData: any;
  zipCodeAddress: any;
  formAddress: string;
  formShipAddress: string;
  formZipCode: string;
  formShipCode: string;
  showAddressError: boolean;
  showCityError: boolean;
  showStateError: boolean;
  showZipcodeError: boolean;
  isValidating: boolean;
  showShipAddressError: boolean;
  showShipCityError: boolean;
  showShipStateError: boolean;
  showShipZipcodeError: boolean;
  showPoBoxErrMsg: boolean;
  showShipPoBoxErrMsg: boolean;
  isLoggedIn: boolean;

  infoVerifySubs: Subscription;
  infoProdSubs: Subscription;
  saveInfoSubs: Subscription;
  infoShipSubs: Subscription;
  infoModalSubs: Subscription;
  infoModalShipSubs: Subscription;
  infoAddressSubs: Subscription;
  infoShipAddSubs: Subscription;
  infoGetServiceSubs: Subscription;
  infoGetAddServiceSubs: Subscription;
  fullAddSubs: Subscription;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private appConfig: AppConfig,
    private memberFormBuilder: FormBuilder,
    private shippingFormBuilder: FormBuilder,
    private authService: AuthService,
    private formsService: FormsService,
    private memberModal: NgbModal,
    private searchService: SearchService
  ) {
    super();
  }

  ngOnInit() {
    this.memberForm = this.memberFormBuilder.group({
      firstName: [
        {
          value: '',
          disabled: true
        },
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      middleName: [
        '',
        [
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.maxLength(40)
        ]
      ],
      lastName: [
        {
          value: '',
          disabled: true
        },
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      aptNo: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      addressOverride: [false],
      email: [
        '',
        [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]
      ],
      emailMatch: [''],
      phone: [
        { value: '', disabled: true },
        [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]
      ],
      language: ['', Validators.required],
      gender: ['', Validators.required],
      dob: [
        { value: '', disabled: true },
        Validators.required
      ],
      houseKeyExists: [false, Validators.required],
      locationOfHouseKey: [''],
      shippingInfo: [false, Validators.required],
    },
      {
        validator: [matchField('email', 'emailMatch')]
      });

    this.startFormDate = new Date(1940, 0, 1); // Set the start year for Date of Birth
    this.languages = languages;
    this.states = states;
    this.shippingStates = states;
    this.gender = gender;
    this.isValidating = false;
    this.showAddressError = false;
    this.showCityError = false;
    this.showStateError = false;
    this.showZipcodeError = false;
    this.showPoBoxErrMsg = false;
    this.showShipAddressError = false;
    this.showShipCityError = false;
    this.showShipStateError = false;
    this.showShipZipcodeError = false;
    this.showShipPoBoxErrMsg = false;
    // Initialize the Member Form
    this.shippingForm = this.shippingFormBuilder.group({
      shipFirstName: [
        '',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      shipMiddleName: [
        '',
        [
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.maxLength(40)
        ]
      ],
      shipLastName: [
        '',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.minLength(3),
          Validators.maxLength(40)
        ]
      ],
      shipAddressOne: ['', Validators.required],
      shipAddressTwo: [''],
      shipAptNo: [''],
      shipCity: ['', Validators.required],
      shipState: ['', Validators.required],
      shipZipCode: ['', Validators.required],
      shipEmail: ['', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]],
      shipEmailMatch: [''],
      shipPhone: ['', [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]],
      shipPhoneMatch: ['']
    },
      {
        validator: [matchField('shipEmail', 'shipEmailMatch'), matchField('shipPhone', 'shipPhoneMatch')]
      });

    this.formZipCode = '';
    this.formShipCode = '';
    this.isDifferentShipAddressSelected = false;

    if (this.memberForm.controls.shippingInfo.value === null || this.memberForm.controls.shippingInfo.value === '') {
      this.memberForm.controls.shippingInfo.setValue(this.isDifferentShipAddressSelected);
    }

    // Set First Name and Last Name from the membership verification form
    this.infoVerifySubs = this.formsService.formMemberVerification$.subscribe(memberVerification => {
      if (memberVerification && memberVerification.controls && Object.keys(memberVerification.controls).length > 0) {
        // Enable the fields
        const memberDate = memberVerification.controls.dob.value;
        this.memberForm.patchValue({
          firstName: memberVerification.controls.firstName.value,
          lastName: memberVerification.controls.lastName.value,
          dob: moment([memberDate.year, memberDate.month - 1, memberDate.day]).format('YYYY-MM-DD')
        });
      }
    });

    this.infoProdSubs = this.formsService.formProductInfo$.subscribe(phoneFormResponse => {
      if (phoneFormResponse && phoneFormResponse.controls && Object.keys(phoneFormResponse.controls).length > 0) {
        this.memberForm.patchValue({
          phone: (phoneFormResponse.controls.lineChoice.value === '1') ?
            phoneFormResponse.controls.lineNo.value :
            phoneFormResponse.controls.cellNo.value
        });
      }
    });

    // Modal Show Save Button
    this.saveInfoSubs = this.formsService.showSaveButton$.subscribe(showSaveButton => {
      this.showSaveButton = showSaveButton;
    });

    // Modal Persist Values
    if (this.showSaveButton) {
      this.infoModalSubs = this.formsService.formMemberInfo$.subscribe(memberInformation => {
        if (memberInformation) {
          this.memberFormPatch(memberInformation);
          this.isDifferentShipAddressSelected = memberInformation.controls.shippingInfo.value;
        }
      });
      this.infoModalShipSubs = this.formsService.formShippingInfo$.subscribe(shippingInformation => {
        if (shippingInformation) {
          this.shippingFormPatch(shippingInformation);
        }
      });
    }

    // Member Info Form Persist Values
    if (this.formsService.memberInfoDetails) {
      this.memberForm = this.formsService.memberInfoDetails;
    }

    // Shipping Form Persist Values
    if (this.formsService.memberShippingDetails) {
      this.shippingForm = this.formsService.memberShippingDetails;
    }

    this.memberForm.get('zipCode').valueChanges.subscribe(() => {
      if (this.formZipCode !== '') {
        this.showAddressError = (this.memberForm.controls.zipCode.value === this.formZipCode) ? false : true;
      }
    });

    this.shippingForm.get('shipZipCode').valueChanges.subscribe(() => {
      if (this.formShipCode !== '') {
        this.showShipAddressError = (this.shippingForm.controls.shipZipCode.value === this.formShipCode) ? false : true;
      }
    });

    this.authService.isLoggedIn$().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    const selectedCustomer = this.searchService.getSelectedCustomer();

    if (!this.memberForm.controls.addressOne.value && selectedCustomer) {
      if (selectedCustomer.addrLine1) {
        this.memberForm.controls.addressOne.setValue(selectedCustomer.addrLine1.toUpperCase());
      }

      if (selectedCustomer.addrLine2) {
        this.memberForm.controls.addressTwo.setValue(selectedCustomer.addrLine2.toUpperCase());
      }

      if (selectedCustomer.city) {
        this.memberForm.controls.city.setValue(selectedCustomer.city.toUpperCase());
      }

      this.memberForm.controls.state.setValue(this.abbreviateState(selectedCustomer.state));
      this.memberForm.controls.zipCode.setValue(selectedCustomer.zip);
    }

    this.checkSelection();
  }

  checkSelection() {
    const formMbrControl = this.memberForm.controls;
    const hiddenKeyLocation = this.memberForm.get('locationOfHouseKey');

    if (formMbrControl.language.value === null) {
      formMbrControl.language.setValue('');
    }

    if (formMbrControl.gender.value === null) {
      formMbrControl.gender.setValue('');
    }

    this.memberForm.get('houseKeyExists').valueChanges
      .subscribe(houseKeyExist => {
        if (houseKeyExist) {
          hiddenKeyLocation.setValidators([Validators.required, Validators.pattern(/\S/)]);
        } else {
          hiddenKeyLocation.clearValidators();
          hiddenKeyLocation.updateValueAndValidity();
        }
      });
  }

  getMemberAddress(event: any) {
    this.memberAddressData.suggestions.filter(opt => {
      if (opt.text.toLowerCase().includes(event.option.viewValue.toLowerCase())) {
        this.memberForm.controls.city.setValue(opt.city.toUpperCase());
        this.memberForm.controls.state.setValue(opt.state);
        this.getFullAddress(opt.street_line, opt.city, opt.state, opt.zipCode, true);
      }
    });
  }

  getShippingAddress(event: any) {
    this.shippingAddressData.suggestions.filter(shippingForm => {
      if (shippingForm.text.toLowerCase().includes(event.option.viewValue.toLowerCase())) {
        this.shippingForm.controls.shipCity.setValue(shippingForm.city.toUpperCase());
        this.shippingForm.controls.shipState.setValue(shippingForm.state);
        this.getFullAddress(shippingForm.street_line, shippingForm.city, shippingForm.state, shippingForm.zipCode, false);
      }
    });
  }

  getFullAddress(street: string, city: string, state: string, zip: string, useHomeAddress: boolean) {
    this.fullAddSubs = this.formsService.getFullAddressInfo(street, city, state, zip).subscribe(fullAddressResponse => {
      this.zipCodeAddress = fullAddressResponse;

      if (this.zipCodeAddress.length > 0) {
        if (useHomeAddress) {
          this.memberForm.controls.zipCode.setValue(this.zipCodeAddress[0].components.zipcode);
          this.showAddressError = false;
        } else {
          this.shippingForm.controls.shipZipCode.setValue(this.zipCodeAddress[0].components.zipcode);
          this.showShipAddressError = false;
        }
      } else {
        if (useHomeAddress) {
          this.showAddressError = true;
        } else {
          this.showShipAddressError = true;
        }
      }
    });
  }

  enableFields() {
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((careDetails: { name: string }) => {
      const memberCareName = careDetails.name.toLowerCase();
      if (memberCareName === 'uhc') {
        this.memberForm.controls.firstName.enable();
        this.memberForm.controls.firstName.setValue('');
        this.memberForm.controls.lastName.enable();
        this.memberForm.controls.lastName.setValue('');
      }
    });
  }

  checkFormAddresses() {
    const isAddressValidFn: ((isAddressValid: boolean) => void)[] = [
      (isAddressValid: boolean) => this.showAddressError = !isAddressValid,
      (isAddressValid: boolean) => this.showShipAddressError = !isAddressValid
    ];

    const isCityValidFn: ((isCityValid: boolean) => void)[] = [
      (isCityValid: boolean) => this.showCityError = !isCityValid,
      (isCityValid: boolean) => this.showShipCityError = !isCityValid
    ];

    const isStateValidFn: ((isStateValid: boolean) => void)[] = [
      (isStateValid: boolean) => this.showStateError = !isStateValid,
      (isStateValid: boolean) => this.showShipStateError = !isStateValid
    ];

    const isZipcodeValidFn: ((isZipcodeValid: boolean) => void)[] = [
      (isZipcodeValid: boolean) => this.showZipcodeError = !isZipcodeValid,
      (isZipcodeValid: boolean) => this.showShipZipcodeError = !isZipcodeValid
    ];

    const suggestZipFn: ((zip: string) => void)[] = [
      (zip: string) => this.formZipCode = zip,
      (zip: string) => this.formShipCode = zip
    ];

    const suggestAddressFn: ((address: string) => void)[] = [
      (address: string) => this.formAddress = address,
      (address: string) => this.formShipAddress = address
    ];

    const poBoxErrMessageFn: ((isPoBoxEntered: boolean) => void)[] = [
      (isPoBoxEntered: boolean) => this.showPoBoxErrMsg = isPoBoxEntered,
      (isPoBoxEntered: boolean) => this.showShipPoBoxErrMsg = isPoBoxEntered
    ];

    const addressInfos: Observable<any>[] = [];
    const userSelected = [];
    const street = this.memberForm.controls.addressOne.value;
    const city = this.memberForm.controls.city.value;
    const state = this.memberForm.controls.state.value;
    const zipCode = this.memberForm.controls.zipCode.value;

    userSelected.push({
      street,
      city,
      state,
      zipCode
    });

    addressInfos.push(this.formsService.getFullAddressInfo(street, city, state, zipCode)
      .pipe(catchError((err: unknown) => of(err))));

    if (this.isDifferentShipAddressSelected) {
      const shipAddressOne = this.shippingForm.controls.shipAddressOne.value;
      const shipCity = this.shippingForm.controls.shipCity.value;
      const shipState = this.shippingForm.controls.shipState.value;
      const shipZipCode = this.shippingForm.controls.shipZipCode.value;

      userSelected.push({
        street: shipAddressOne,
        city: shipCity,
        state: shipState,
        zipCode: shipZipCode
      });

      addressInfos.push(this.formsService.getFullAddressInfo(shipAddressOne, shipCity, shipState, shipZipCode)
        .pipe(catchError((err: unknown) => of(err))));
    }

    forkJoin(addressInfos).subscribe(next => {
      let isFormValid = true;
      next.forEach((address, index) => {
        isAddressValidFn[index](true);
        isCityValidFn[index](true);
        isStateValidFn[index](true);
        isZipcodeValidFn[index](true);
        poBoxErrMessageFn[index](false);
        suggestZipFn[index]('');
        suggestAddressFn[index]('');

        if (address instanceof TimeoutError || address instanceof HttpErrorResponse) {
          if (this.isLoggedIn) {
            this.showAddressError = true;
            isFormValid = this.memberForm.controls.addressOverride.value;

            if (isFormValid) {
              const httpErrorMessage = address instanceof TimeoutError ?
                `Address verification resulted in HTTP timeout. Allowing logged-in user to continue, but investigate for service issues.` :
                address instanceof HttpErrorResponse ?
                  `Address verification resulted in HTTP error ${address.error.code} ${address.error.description}. ` +
                  `Allowing logged-in user to continue, but investigate for service issues.` : '';
              AwsRumFunctions.callCwr('recordError', httpErrorMessage);
              if (this.appConfig.config.debug) {
                console.error(httpErrorMessage);
              }
            }
          } else {
            isFormValid = false;
          }
        } else if (address.length === 0) {
          isAddressValidFn[index](false);
          isCityValidFn[index](false);
          isStateValidFn[index](false);
          isZipcodeValidFn[index](false);
        } else {
          if (userSelected[index].city === null || address[0].components.city_name === null ||
            userSelected[index].city.toUpperCase() !== address[0].components.city_name.toUpperCase()
          ) {
            isCityValidFn[index](false);
          }
          if (userSelected[index].street === null || address[0].components.street_name === null ||
            userSelected[index].street.toUpperCase() !== address[0].delivery_line_1.toUpperCase()
          ) {
            isAddressValidFn[index](false);
            suggestAddressFn[index](address[0].delivery_line_1);
          }
          if (userSelected[index].state === null || address[0].components.state_abbreviation === null ||
            userSelected[index].state.toUpperCase() !== address[0].components.state_abbreviation.toUpperCase()
          ) {
            isStateValidFn[index](false);
          }
          if (address[0].components.street_name === 'PO Box') {
            poBoxErrMessageFn[index](true);
            isAddressValidFn[index](false);
          }
          if (userSelected[index].zipCode !== address[0].components.zipcode) {
            isZipcodeValidFn[index](false);
            suggestZipFn[index](address[0].components.zipcode);
          }
        }
        isFormValid = isFormValid &&
          (this.memberForm.controls.addressOverride.value ||
            (!this.showAddressError &&
              !this.showCityError &&
              !this.showStateError &&
              !this.showZipcodeError)) &&
          !this.showShipAddressError &&
          !this.showShipCityError &&
          !this.showShipStateError &&
          !this.showShipZipcodeError;
      });
      if (isFormValid) {
        this.save();
      }
      this.isValidating = false;
    });
  }

  save() {
    if (!this.isDifferentShipAddressSelected && this.memberForm.valid) {
      this.formsService.formMemberInfo$.next(this.memberForm);
      this.formsService.formShippingInfo$.next(this.shippingForm);
      if (!this.showSaveButton) {
        this.nextEvent.emit();
      } else {
        this.memberModal.dismissAll();
      }
    } else if (this.isDifferentShipAddressSelected && this.memberForm.valid && this.shippingForm.valid) {
      this.formsService.formMemberInfo$.next(this.memberForm);
      this.formsService.formShippingInfo$.next(this.shippingForm);
      if (!this.showSaveButton) {
        this.nextEvent.emit();
      } else {
        this.memberModal.dismissAll();
      }
    }
  }

  goToFormCare() {
    if (this.isDifferentShipAddressSelected) {
      this.shippingForm.markAllAsTouched();
    } else {
      if (this.shippingForm) {
        this.shippingForm.reset(this.formsService.shippingDetailsEmptyFormValues);
      }
      this.showShipAddressError = false;
      this.showShipCityError = false;
      this.showShipStateError = false;
      this.showShipZipcodeError = false;
      this.showShipPoBoxErrMsg = false;
    }
    this.isValidating = true;
    this.memberForm.markAllAsTouched();
    this.checkFormAddresses();
  }

  goToFormProd() {
    this.previousEvent.emit(1);
  }

  memberFormPatch(memberValues: FormGroup) {
    if (!memberValues || !memberValues.controls) {
      return;
    }

    this.memberForm.patchValue({
      firstName: memberValues.controls.firstName.value,
      middleName: memberValues.controls.middleName.value,
      lastName: memberValues.controls.lastName.value,
      addressOne: memberValues.controls.addressOne.value,
      addressTwo: memberValues.controls.addressTwo.value,
      aptNo: memberValues.controls.aptNo.value,
      city: memberValues.controls.city.value,
      state: memberValues.controls.state.value,
      zipCode: memberValues.controls.zipCode.value,
      addressOverride: memberValues.controls.addressOverride.value,
      email: memberValues.controls.email.value,
      emailMatch: memberValues.controls.emailMatch.value,
      phone: memberValues.controls.phone.value,
      language: memberValues.controls.language.value,
      gender: memberValues.controls.gender.value,
      dob: memberValues.controls.dob.value,
      houseKeyExists: memberValues.controls.houseKeyExists.value,
      locationOfHouseKey: memberValues.controls.locationOfHouseKey.value,
      shippingInfo: memberValues.controls.shippingInfo.value,
    });
  }

  shippingFormPatch(shipValues: FormGroup) {
    if (!shipValues || !shipValues.controls) {
      return;
    }

    this.shippingForm.patchValue({
      shipFirstName: shipValues.controls.shipFirstName.value,
      shipMiddleName: shipValues.controls.shipMiddleName.value,
      shipLastName: shipValues.controls.shipLastName.value,
      shipAddressOne: shipValues.controls.shipAddressOne.value,
      shipAddressTwo: shipValues.controls.shipAddressTwo.value,
      shipAptNo: shipValues.controls.shipAptNo.value,
      shipCity: shipValues.controls.shipCity.value,
      shipState: shipValues.controls.shipState.value,
      shipZipCode: shipValues.controls.shipZipCode.value,
      shipEmail: shipValues.controls.shipEmail.value,
      shipEmailMatch: shipValues.controls.shipEmailMatch.value,
      shipPhone: shipValues.controls.shipPhone.value,
      shipPhoneMatch: shipValues.controls.shipPhoneMatch.value,
    });
  }

  ngOnDestroy() {
    this.formsService.memberInfoDetails = this.memberForm;
    this.formsService.memberShippingDetails = this.shippingForm;
    this.destroy$.next();
    this.destroy$.complete();
  }

  private abbreviateState(fullStateName: string): string {
    const matchingState = states.find(state => state.fullName === fullStateName);
    if (matchingState) {
      return matchingState.name;
    } else {
      return fullStateName;
    }
  }
}
