import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maskSensitive'
})
export class MaskSensitivePipe implements PipeTransform {
    transform(value: string, visibleCharacters: number = 4): string {
        if (!value || value.length <= visibleCharacters) {
            return value;
        }
        return 'x'.repeat(value.length - visibleCharacters) + value.slice(-visibleCharacters);
    }
}
