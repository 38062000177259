/* eslint-disable @typescript-eslint/naming-convention -- Must match format expected by Platform API */
export enum PlatformRelationshipType {
    Unknown = 'Unknown',
    Aunt = 'Aunt',
    Brother = 'Brother',
    Caretaker = 'Caretaker',
    Daughter = 'Daughter',
    Doctor = 'Doctor',
    ExHusband = 'Ex_Husband',
    ExWife = 'Ex_Wife',
    Father = 'Father',
    Friend = 'Friend',
    Granddaughter = 'Granddaughter',
    Grandfather = 'Grandfather',
    Grandmother = 'Grandmother',
    Grandparent = 'Grandparent',
    Grandson = 'Grandson',
    Husband = 'Husband',
    Mother = 'Mother',
    Niece = 'Niece',
    Neighbor = 'Neighbor',
    Nephew = 'Nephew',
    None = 'None',
    Nurse = 'Nurse',
    Other = 'Other',
    Relative = 'Relative',
    Self = 'Self',
    Sibling = 'Sibling',
    Sister = 'Sister',
    Son = 'Son',
    Spouse = 'Spouse',
    Uncle = 'Uncle',
    Wife = 'Wife'
}

export const getPlatformRelationshipType = (relationshipString: string): PlatformRelationshipType =>
    !relationshipString ? null : PlatformRelationshipType[relationshipString] ||
        Object.values(PlatformRelationshipType).find((relationship) =>
            relationship.toUpperCase() === relationshipString.toUpperCase());
