import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-stepper-control',
  templateUrl: './stepper-control.component.html',
  styleUrls: ['./stepper-control.component.css']
})
export class StepperControlComponent implements OnInit {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  showMemberNextButton = false; // Show Next Button in Membership Verification Form
  isMembershipNextButton = true; // Disable/Enable Next Button in Membership Verification Form
  showExitButton = false; // Show Exit Button during Product Selection
  showProductNextButton = false; // Show/Hide Next Button in Product Form

  isFireFox: boolean;
  isSafari: boolean;
  isIEorEdge: boolean;
  isChrome: boolean;
  isOpera: boolean;

  mobileDevice: boolean;

  constructor() {
    this.isFireFox = /firefox/i.test(navigator.userAgent);
    this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    this.isIEorEdge = /msie\s|trident\/|edge\//.test(window.navigator.userAgent);
    this.isChrome = /Google Inc/.test(navigator.vendor);
    this.isOpera = /OPR/.test(navigator.userAgent);
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 320 && screenWidth < 768) {
      this.mobileDevice = true;
    }
  }

  ngOnInit(): void {
    this.mobileDevice = false;
  }

  goToNextForm(): void {
    this.stepper.next();
  }

  goToPreviousForm(): void {
    this.stepper.previous();
  }
}
