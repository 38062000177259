import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of, TimeoutError } from 'rxjs';
import { yearAndMonthIsFutureValidator } from 'src/app/validators/year-and-month-is-future.validator';

import * as moment from 'moment';
import gender from '../../../../assets/json/gender.json';
import languages from '../../../../assets/json/languages.json';
import states from '../../../../assets/json/states.json';
import { AuthService } from 'src/app/shared/services/auth.service';
import { VaisFormsService } from '../../services';
import { matchField } from '../../../validators/match-field.validator';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { AwsRumFunctions } from 'src/app/shared/aws-rum-functions.service';
import { creditCardValidator } from 'src/app/validators/credit-card.validator';
import { monthValidator } from 'src/app/validators/month.validator';
import { yearRangeValidator } from 'src/app/validators/year.validator';
import { SmartyStreetsResponse } from 'src/app/shared/models/smarty-streets-response.model';
import { State } from 'src/app/shared/models/state.model';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MEMBER_ADDRESS_DEFAULTS, MemberAddress } from '../../models/member-address.model';
import { PaymentLineItem } from 'src/app/shared/models/payment-line-item.model';

@Component({
  selector: 'app-vais-member-information',
  templateUrl: './vais-member-information.component.html',
  styleUrls: ['./vais-member-information.component.css']
})
export class VaisMemberInformationComponent extends FormCanDeactivate implements OnInit {
  @Output() nextEvent = new EventEmitter<void>();
  @Output() previousEvent = new EventEmitter<void>();
  @ViewChild('formThree', { static: false }) form: NgForm;

  memberInformationForm: FormGroup;
  languages = languages;
  states = states.sort((a: State, b: State) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
  genders = gender;

  showAsModal: boolean;
  showPoBoxErrMsg = false;

  isValidating = false;
  isLoggedIn: boolean;
  isFootnoteVisible = false;

  suggestedServiceAddress: MemberAddress = { ...MEMBER_ADDRESS_DEFAULTS };
  badServiceAddress: MemberAddress = { ...MEMBER_ADDRESS_DEFAULTS };
  suggestedShippingAddress: MemberAddress = { ...MEMBER_ADDRESS_DEFAULTS };
  badShippingAddress: MemberAddress = { ...MEMBER_ADDRESS_DEFAULTS };

  cannotReachAddressValidationService = false;

  paymentLineItems: PaymentLineItem[] = [];

  private nameValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(3),
    Validators.maxLength(40),
  ];
  private middleNameValidators = [
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.maxLength(40)
  ];
  private address1Validators = [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(30)
  ];
  private shippingAddress1Validators = [
    Validators.required,
    (): ValidationErrors | null =>
      this.suggestedShippingAddress.address ?
        { correctedAddressSuggested: this.suggestedShippingAddress.address } : null,
    (): ValidationErrors | null =>
      this.badShippingAddress.isUnknown ? { isUnknownAddress: true } : null,
    (): ValidationErrors | null =>
      this.cannotReachAddressValidationService ?
        { cannotReachAddressValidationService: true } : null,
    (): ValidationErrors | null =>
      this.badShippingAddress.isPoBox ? { isPoBox: true } : null
  ];
  private address2Validators = [
    Validators.maxLength(30)
  ];
  private cityValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(3),
    Validators.maxLength(40)
  ];
  private shippingCityValidators = [
    ...this.cityValidators,
    (): ValidationErrors | null =>
      this.suggestedShippingAddress.city ?
        { correctedAddressSuggested: this.suggestedShippingAddress.city } : null
  ];
  private stateValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(2),
    Validators.maxLength(2)
  ];
  private shippingStateValidators = [
    ...this.stateValidators,
    (): ValidationErrors | null =>
      this.suggestedShippingAddress.state ?
        { correctedAddressSuggested: this.suggestedShippingAddress.state } : null
  ];
  private zipCodeValidators = [
    Validators.required,
    Validators.pattern(/^[0-9]{5}(-[0-9]{4})?$/),
    Validators.minLength(5),
  ];
  private shippingZipCodeValidators = [
    ...this.zipCodeValidators,
    (): ValidationErrors | null =>
      this.suggestedShippingAddress.zipCode ?
        { correctedAddressSuggested: this.suggestedShippingAddress.zipCode } : null
  ];
  private phoneNumberValidators = [
    Validators.required,
    Validators.pattern(/^\d{3}-\d{3}-\d{4}$/),
    Validators.minLength(12),
    Validators.maxLength(12)
  ];

  private routingNumberValidators = [Validators.required, Validators.pattern(/^\d{9}$/)];
  private accountNumberValidators = [Validators.required, Validators.pattern(/^\d{8,17}$/)];

  private cardholderNameValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(3),
    Validators.maxLength(40)
  ];
  private cardNumberValidators = [
    Validators.required,
    Validators.pattern(/^\d{13,19}$/),
    creditCardValidator()
  ];
  private expirationDateMonthValidators = [
    Validators.required,
    Validators.pattern(/^\d{2}$/),
    monthValidator()
  ];
  private expirationDateYearValidators = [
    Validators.required,
    Validators.pattern(/^\d{4}$/),
    yearRangeValidator(new Date().getFullYear(), new Date().getFullYear() + 10)
  ];

  constructor(
    private appConfig: AppConfig,
    private memberInformationFormBuilder: FormBuilder,
    private authService: AuthService,
    private vaisFormsService: VaisFormsService,
    private modal: NgbModal,
    private searchService: SearchService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  get isShippingAddressDifferent(): boolean {
    return this.memberInformationForm && this.memberInformationForm.value.isShippingAddressDifferent;
  }

  get isPaymentAddressDifferent(): boolean {
    return this.memberInformationForm && this.memberInformationForm.value.isPaymentAddressDifferent;
  }

  ngOnInit() {
    this.memberInformationForm = this.memberInformationFormBuilder.group({
      firstName: [{ value: '', disabled: true }],
      middleName: ['', this.middleNameValidators],
      lastName: [{ value: '', disabled: true }],
      address1: [
        '',
        [
          ...this.address1Validators,
          (): ValidationErrors | null =>
            this.suggestedServiceAddress.address ?
              { correctedAddressSuggested: this.suggestedServiceAddress.address } : null,
          (): ValidationErrors | null =>
            this.badServiceAddress.isUnknown ? { isUnknownAddress: true } : null,
          (): ValidationErrors | null =>
            this.cannotReachAddressValidationService ? { cannotReachAddressValidationService: true } : null,
          (): ValidationErrors | null =>
            this.badServiceAddress.isPoBox ? { isPoBox: true } : null
        ]
      ],
      address2: ['', this.address2Validators],
      city: [
        '',
        [
          ...this.cityValidators,
          (): ValidationErrors | null =>
            this.suggestedServiceAddress.city ?
              { correctedAddressSuggested: this.suggestedServiceAddress.city } : null
        ]
      ],
      state: [
        '',
        [
          ...this.stateValidators,
          (): ValidationErrors | null =>
            this.suggestedServiceAddress.state ?
              { correctedAddressSuggested: this.suggestedServiceAddress.state } : null
        ]
      ],
      zipCode: [
        '',
        [
          ...this.zipCodeValidators,
          (): ValidationErrors | null =>
            this.suggestedServiceAddress.zipCode ?
              { correctedAddressSuggested: this.suggestedServiceAddress.zipCode } : null
        ]
      ],
      addressOverride: [false],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        ]
      ],
      emailMatch: ['', Validators.required],
      phoneNumber: [{ value: '', disabled: true }],
      language: ['', Validators.required],
      gender: ['', Validators.required],
      dob: [
        { value: '', disabled: true },
        Validators.required
      ],
      houseKeyExists: [false, Validators.required],
      locationOfHouseKey: [''],

      isShippingAddressDifferent: [false],
      shippingFirstName: [''],
      shippingLastName: [''],
      shippingAddress1: [''],
      shippingAddress2: [''],
      shippingCity: [''],
      shippingState: [''],
      shippingZipCode: [''],
      shippingPhoneNumber: [''],
      shippingAddressOverride: [false],

      isPaymentAddressDifferent: [false],
      payerFirstName: [''],
      payerLastName: [''],
      paymentPhoneNumber: [''],
      paymentAddress1: [''],
      paymentAddress2: [''],
      paymentCity: [''],
      paymentState: [''],
      paymentZipCode: [''],

      isPayingByACH: [null, Validators.required],
      routingNumber: [''],
      accountNumber: [''],
      cardholderName: [''],
      cardNumber: [''],
      expirationDateMonth: [''],
      expirationDateYear: [''],
    },
      {
        validators: [
          matchField('email', 'emailMatch'),
          yearAndMonthIsFutureValidator('expirationDateMonth', 'expirationDateYear')
        ]
      }
    );

    this.vaisFormsService.couponVerificationForm$.subscribe(memberVerification => {
      if (memberVerification && memberVerification.controls && Object.keys(memberVerification.controls).length > 0) {
        const memberDate = memberVerification.value.dob;
        this.memberInformationForm.patchValue({
          firstName: memberVerification.value.firstName,
          lastName: memberVerification.value.lastName,
          dob: moment([memberDate.year, memberDate.month - 1, memberDate.day]).format('YYYY-MM-DD')
        });
      }
    });

    this.vaisFormsService.couponVerificationForm$.subscribe(couponVerificationForm => {
      this.memberInformationForm.patchValue({
        firstName: couponVerificationForm.value.firstName,
        lastName: couponVerificationForm.value.lastName,
        zipCode: couponVerificationForm.value.zipCode
      });
    });

    this.vaisFormsService.productSelectionForm$.subscribe(phoneFormResponse => {
      if (phoneFormResponse && phoneFormResponse.controls && Object.keys(phoneFormResponse.controls).length > 0) {
        this.memberInformationForm.patchValue({
          phoneNumber: phoneFormResponse.value.phoneNumber
        });
      }
    });

    this.authService.isLoggedIn$().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this.memberInformationForm.controls.isShippingAddressDifferent.valueChanges.subscribe((value: boolean) => {
      this.setShippingAddressValidators(value);
    });

    this.memberInformationForm.controls.isPaymentAddressDifferent.valueChanges.subscribe((value: boolean) => {
      this.setPaymentAddresssValidators(value);
    });

    this.vaisFormsService.showAsModal$.pipe(
      tap(showAsModal => {
        this.showAsModal = showAsModal;

        if (!this.showAsModal) {
          this.loadCustomerIfReferredFromSearch();
        }
      }),
      filter(showAsModal => showAsModal === true),
      switchMap(() => this.vaisFormsService.memberInformationForm$)
    ).subscribe(memberInformation => {
      if (memberInformation) {
        this.memberInformationFormPatch(memberInformation);
        this.setValidatorsByPaymentMethod(memberInformation.value.isPayingByACH);
      }
    });

    this.watchValueChangesOnForm();
  }

  goToPreviousStep() {
    this.previousEvent.emit();
  }

  goToNextStep() {
    this.memberInformationForm.markAllAsTouched();
    this.changeDetectorRef.detectChanges();

    if (!this.memberInformationForm.valid) {
      if (this.appConfig.config.debug) {
        console.log(this.vaisFormsService.debugAllValidators(this.memberInformationForm));
      }
      this.vaisFormsService.scrollToInvalidControl(this.memberInformationForm);
      return;
    }

    this.isValidating = true;

    this.areAllAddressesValid$().subscribe((areAllAddressesValid) => {
      if (areAllAddressesValid) {
        this.memberInformationForm.updateValueAndValidity();
        if (!this.memberInformationForm.valid) {
          if (this.appConfig.config.debug) {
            console.log(this.vaisFormsService.debugAllValidators(this.memberInformationForm));
          }
          this.vaisFormsService.scrollToInvalidControl(this.memberInformationForm);
          return;
        }

        this.vaisFormsService.memberInformationForm$.next(this.memberInformationForm);
        if (this.showAsModal) {
          this.modal.dismissAll();
        } else {
          this.nextEvent.emit();
        }
      }
    }, () => {
      this.isValidating = false;
    });
  }

  toggleFootnote(state: boolean = null) {
    if (state === null) {
      this.isFootnoteVisible = !this.isFootnoteVisible;
    } else {
      this.isFootnoteVisible = state;
    }
  }

  private setShippingAddressValidators(isDifferentFromServiceAddress: boolean) {
    if (isDifferentFromServiceAddress) {
      this.memberInformationForm.controls.shippingFirstName.setValidators(this.nameValidators);
      this.memberInformationForm.controls.shippingLastName.setValidators(this.nameValidators);
      this.memberInformationForm.controls.shippingAddress1.setValidators(this.shippingAddress1Validators);
      this.memberInformationForm.controls.shippingAddress2.setValidators(this.address2Validators);
      this.memberInformationForm.controls.shippingCity.setValidators(this.shippingCityValidators);
      this.memberInformationForm.controls.shippingState.setValidators(this.shippingStateValidators);
      this.memberInformationForm.controls.shippingZipCode.setValidators(this.shippingZipCodeValidators);
      this.memberInformationForm.controls.shippingPhoneNumber.setValidators(this.phoneNumberValidators);
    } else {
      this.memberInformationForm.controls.shippingFirstName.clearValidators();
      this.memberInformationForm.controls.shippingLastName.clearValidators();
      this.memberInformationForm.controls.shippingAddress1.clearValidators();
      this.memberInformationForm.controls.shippingAddress2.clearValidators();
      this.memberInformationForm.controls.shippingCity.clearValidators();
      this.memberInformationForm.controls.shippingState.clearValidators();
      this.memberInformationForm.controls.shippingZipCode.clearValidators();
      this.memberInformationForm.controls.shippingPhoneNumber.clearValidators();
    }

    this.memberInformationForm.controls.shippingFirstName.updateValueAndValidity();
    this.memberInformationForm.controls.shippingLastName.updateValueAndValidity();
    this.memberInformationForm.controls.shippingAddress1.updateValueAndValidity();
    this.memberInformationForm.controls.shippingAddress2.updateValueAndValidity();
    this.memberInformationForm.controls.shippingCity.updateValueAndValidity();
    this.memberInformationForm.controls.shippingState.updateValueAndValidity();
    this.memberInformationForm.controls.shippingZipCode.updateValueAndValidity();
    this.memberInformationForm.controls.shippingPhoneNumber.updateValueAndValidity();
  }

  private setPaymentAddresssValidators(isDifferentFromServiceAddress: boolean) {
    if (isDifferentFromServiceAddress) {
      this.memberInformationForm.controls.payerFirstName.setValidators(this.nameValidators);
      this.memberInformationForm.controls.payerLastName.setValidators(this.nameValidators);
      this.memberInformationForm.controls.paymentPhoneNumber.setValidators(this.phoneNumberValidators);
      this.memberInformationForm.controls.paymentAddress1.setValidators(this.address1Validators);
      this.memberInformationForm.controls.paymentAddress2.setValidators(this.address2Validators);
      this.memberInformationForm.controls.paymentCity.setValidators(this.cityValidators);
      this.memberInformationForm.controls.paymentState.setValidators(this.stateValidators);
      this.memberInformationForm.controls.paymentZipCode.setValidators(this.zipCodeValidators);
    } else {
      this.memberInformationForm.controls.payerFirstName.clearValidators();
      this.memberInformationForm.controls.payerLastName.clearValidators();
      this.memberInformationForm.controls.paymentPhoneNumber.clearValidators();
      this.memberInformationForm.controls.paymentAddress1.clearValidators();
      this.memberInformationForm.controls.paymentAddress2.clearValidators();
      this.memberInformationForm.controls.paymentCity.clearValidators();
      this.memberInformationForm.controls.paymentState.clearValidators();
      this.memberInformationForm.controls.paymentZipCode.clearValidators();
    }

    this.memberInformationForm.controls.payerFirstName.updateValueAndValidity();
    this.memberInformationForm.controls.payerLastName.updateValueAndValidity();
    this.memberInformationForm.controls.paymentPhoneNumber.updateValueAndValidity();
    this.memberInformationForm.controls.paymentAddress1.updateValueAndValidity();
    this.memberInformationForm.controls.paymentAddress2.updateValueAndValidity();
    this.memberInformationForm.controls.paymentCity.updateValueAndValidity();
    this.memberInformationForm.controls.paymentState.updateValueAndValidity();
    this.memberInformationForm.controls.paymentZipCode.updateValueAndValidity();
  }

  private areAllAddressesValid$(): Observable<boolean> {
    const addressValidationRequests = [];

    const serviceAddress: MemberAddress = {
      address: this.memberInformationForm.value.address1,
      city: this.memberInformationForm.value.city,
      state: this.memberInformationForm.value.state,
      zipCode: this.memberInformationForm.value.zipCode,
      isUnknown: false,
      isPoBox: false
    };

    addressValidationRequests.push(this.isAddressValid$(serviceAddress,
      this.suggestedServiceAddress,
      this.badServiceAddress));

    if (this.memberInformationForm.value.isShippingAddressDifferent) {
      const shippingAddress: MemberAddress = {
        address: this.memberInformationForm.value.shippingAddress1,
        city: this.memberInformationForm.value.shippingCity,
        state: this.memberInformationForm.value.shippingState,
        zipCode: this.memberInformationForm.value.shippingZipCode,
        isUnknown: false,
        isPoBox: false
      };

      addressValidationRequests.push(this.isAddressValid$(shippingAddress,
        this.suggestedShippingAddress,
        this.badShippingAddress));
    }

    return forkJoin(addressValidationRequests)
      .pipe(map((addressValidationResponses) => {
        this.isValidating = false;
        this.updateAllAddressValidators(this.memberInformationForm.controls);
        this.memberInformationForm.updateValueAndValidity();
        return addressValidationResponses.every(isAddressValid => isAddressValid === true);
      }));
  }

  private memberInformationFormPatch(memberValues: FormGroup) {
    if (!memberValues || !memberValues.controls) {
      return;
    }

    this.memberInformationForm.patchValue(memberValues.getRawValue());
  }

  private watchValueChangesOnForm() {
    const controls = this.memberInformationForm.controls;

    controls.address1.valueChanges.subscribe(() => {
      this.badServiceAddress.isPoBox = false;
      this.checkIfBadServiceAddressChanged();
      if (this.suggestedServiceAddress.address &&
        controls.address1.value.toUpperCase() === this.suggestedServiceAddress.address.toUpperCase()) {
        this.suggestedServiceAddress.address = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.city.valueChanges.subscribe(() => {
      this.checkIfBadServiceAddressChanged();
      if (this.suggestedServiceAddress.city &&
        controls.city.value.toUpperCase() === this.suggestedServiceAddress.city.toUpperCase()) {
        this.suggestedServiceAddress.city = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.state.valueChanges.subscribe(() => {
      this.checkIfBadServiceAddressChanged();
      if (this.suggestedServiceAddress.state &&
        controls.state.value.toUpperCase() === this.suggestedServiceAddress.state.toUpperCase()) {
        this.suggestedServiceAddress.state = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.zipCode.valueChanges.subscribe(() => {
      this.checkIfBadServiceAddressChanged();
      if (this.suggestedServiceAddress.zipCode &&
        controls.zipCode.value === this.suggestedServiceAddress.zipCode) {
        this.suggestedServiceAddress.zipCode = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.shippingAddress1.valueChanges.subscribe(() => {
      this.checkIfBadShippingAddressChanged();
      this.badShippingAddress.isPoBox = false;
      if (this.suggestedShippingAddress.address &&
        controls.shippingAddress1.value.toUpperCase() === this.suggestedShippingAddress.address.toUpperCase()) {
        this.suggestedShippingAddress.address = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.shippingCity.valueChanges.subscribe(() => {
      this.checkIfBadShippingAddressChanged();
      if (this.suggestedShippingAddress.city &&
        controls.shippingCity.value.toUpperCase() === this.suggestedShippingAddress.city.toUpperCase()) {
        this.suggestedShippingAddress.city = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.shippingState.valueChanges.subscribe(() => {
      this.checkIfBadShippingAddressChanged();
      if (this.suggestedShippingAddress.state &&
        controls.shippingState.value.toUpperCase() === this.suggestedShippingAddress.state.toUpperCase()) {
        this.suggestedShippingAddress.state = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.shippingZipCode.valueChanges.subscribe(() => {
      this.checkIfBadShippingAddressChanged();
      if (this.suggestedShippingAddress.zipCode &&
        controls.shippingZipCode.value === this.suggestedShippingAddress.zipCode) {
        this.suggestedShippingAddress.zipCode = null;
        this.updateAllAddressValidators(controls);
      }
    });

    controls.addressOverride.valueChanges.subscribe(() => {
      if (controls.addressOverride.value) {
        this.suggestedServiceAddress = {
          address: null,
          city: null,
          state: null,
          zipCode: null,
          isUnknown: false,
          isPoBox: false
        };
      };
      this.updateAllAddressValidators(controls);
    });

    controls.shippingAddressOverride.valueChanges.subscribe(() => {
      if (controls.shippingAddressOverride.value) {
        this.suggestedShippingAddress = {
          address: null,
          city: null,
          state: null,
          zipCode: null,
          isUnknown: false,
          isPoBox: false
        };
      };
      this.updateAllAddressValidators(controls);
    });

    controls.houseKeyExists.valueChanges
      .subscribe(houseKeyExist => {
        if (houseKeyExist) {
          controls.locationOfHouseKey.setValidators([Validators.required, Validators.pattern(/\S/)]);
        } else {
          controls.locationOfHouseKey.clearValidators();
          controls.locationOfHouseKey.updateValueAndValidity();
        }
      });

    controls.isPayingByACH.valueChanges
      .subscribe(isPayingByACH => {
        this.setValidatorsByPaymentMethod(isPayingByACH);
      });

    if (!this.memberInformationForm.value.paymentAddress1) {
      this.setPayerAddressToSubscriberAddress();
    }
  }

  private setPayerAddressToSubscriberAddress() {
    const controls = this.memberInformationForm.controls;

    controls.payerLastName.setValue(controls.lastName.value);
    controls.payerFirstName.setValue(controls.firstName.value);
    controls.paymentAddress1.setValue(controls.address1.value);
    controls.paymentAddress2.setValue(controls.address2.value);
    controls.paymentCity.setValue(controls.city.value);
    controls.paymentState.setValue(controls.state.value);
    controls.paymentZipCode.setValue(controls.zipCode.value);
    controls.paymentPhoneNumber.setValue(controls.phoneNumber.value);
  }

  private isAddressValid$(enteredAddress: MemberAddress, suggestedAddress: MemberAddress,
    badAddress: MemberAddress): Observable<boolean> {
    if (!enteredAddress.address || !enteredAddress.city || !enteredAddress.state || !enteredAddress.zipCode) {
      return of(false);
    }

    return this.vaisFormsService.getFullAddressInfo(enteredAddress.address, enteredAddress.city, enteredAddress.state,
      enteredAddress.zipCode)
      .pipe(map((matchingAddresses: SmartyStreetsResponse[]) => {
        Object.assign(suggestedAddress, {
          address: null,
          city: null,
          state: null,
          zipCode: null,
          isUnknown: false,
          isPoBox: false
        });

        if (matchingAddresses instanceof TimeoutError || matchingAddresses instanceof HttpErrorResponse) {
          if (this.isLoggedIn) {
            if (this.memberInformationForm.value.addressOverride) {
              const httpErrorMessage = matchingAddresses instanceof TimeoutError ?
                `Address verification resulted in HTTP timeout. Allowing logged-in user to continue, but investigate for service issues.` :
                matchingAddresses instanceof HttpErrorResponse ?
                  `Address verification resulted in HTTP error ${matchingAddresses.error.code} ${matchingAddresses.error.description}. ` +
                  `Allowing logged-in user to continue, but investigate for service issues.` : '';
              AwsRumFunctions.callCwr('recordError', httpErrorMessage);
              if (this.appConfig.config.debug) {
                console.error(httpErrorMessage);
              }
            }
          }
          return false;
        }

        this.cannotReachAddressValidationService = false;

        if (matchingAddresses.length === 0 || matchingAddresses[0] instanceof HttpErrorResponse) {
          Object.assign(badAddress, enteredAddress, {
            isUnknown: true,
            isPoBox: false
          });
          return false;
        }

        badAddress.isUnknown = false;

        const { delivery_line_1: correctedAddress } = matchingAddresses[0];
        const {
          street_name: correctedStreetName,
          city_name: correctedCity,
          state_abbreviation: correctedState,
          zipcode: correctedZipCode
        } = matchingAddresses[0].components;

        let doAddressesMatch = true;

        if (correctedAddress && correctedAddress.toUpperCase() !== enteredAddress.address.toUpperCase()) {
          doAddressesMatch = false;
          badAddress.address = enteredAddress.address;
          suggestedAddress.address = correctedAddress;
        }

        if (correctedStreetName === 'PO Box') {
          badAddress.isPoBox = true;
          badAddress.address = enteredAddress.address;
        }

        if (correctedCity && correctedCity.toUpperCase() !== enteredAddress.city.toUpperCase()) {
          doAddressesMatch = false;
          badAddress.city = enteredAddress.city;
          suggestedAddress.city = correctedCity;
        }

        if (correctedState && correctedState.toUpperCase() !== enteredAddress.state.toUpperCase()) {
          doAddressesMatch = false;
          badAddress.state = enteredAddress.state;
          suggestedAddress.state = correctedState;
        }

        if (correctedZipCode && correctedZipCode !== enteredAddress.zipCode) {
          doAddressesMatch = false;
          badAddress.zipCode = enteredAddress.zipCode;
          suggestedAddress.zipCode = correctedZipCode;
        }

        return doAddressesMatch && !badAddress.isPoBox;
      }, (errorResponse: unknown) => {
        if (this.isLoggedIn) {
          if (this.memberInformationForm.value.addressOverride) {
            const httpErrorMessage = errorResponse instanceof TimeoutError ?
              `Error reaching address validation service. Allowing logged-in user to continue, but investigate for service issues.` :
              errorResponse instanceof HttpErrorResponse ?
                `Address verification resulted in HTTP error ${errorResponse.error.code} ${errorResponse.error.description}. ` +
                `Allowing logged-in user to continue, but investigate for service issues.` : '';
            AwsRumFunctions.callCwr('recordError', httpErrorMessage);
            if (this.appConfig.config.debug) {
              console.error(httpErrorMessage);
            }
          }
        } else {
          this.cannotReachAddressValidationService = true;

          Object.assign(badAddress, enteredAddress, {
            isUnknown: false,
            isPoBox: false
          });

          this.memberInformationForm.updateValueAndValidity();
        }
        return false;
      }));
  }

  private setValidatorsByPaymentMethod(isPayingByACH: boolean) {
    const controls = this.memberInformationForm.controls;

    if (isPayingByACH) {
      this.setValidatorsForAch(controls);
    } else {
      this.setValidatorsForCreditCard(controls);
    }

    this.updateAllPaymentValidators(controls);
  }

  private checkIfBadServiceAddressChanged() {
    if (this.badServiceAddress.isUnknown || this.cannotReachAddressValidationService) {
      if (this.memberInformationForm.value.address1 !== this.badServiceAddress.address ||
        this.memberInformationForm.value.city !== this.badServiceAddress.city ||
        this.memberInformationForm.value.state !== this.badServiceAddress.state ||
        this.memberInformationForm.value.zipCode !== this.badServiceAddress.zipCode
      ) {
        this.badServiceAddress.isUnknown = false;
        this.cannotReachAddressValidationService = false;
        this.suggestedServiceAddress.address = null;
        this.updateAllAddressValidators(this.memberInformationForm.controls);
      }
    }
  }

  private checkIfBadShippingAddressChanged() {
    if (this.badShippingAddress.isUnknown || this.cannotReachAddressValidationService) {
      if (this.memberInformationForm.value.shippingAddress1 !== this.badShippingAddress.address ||
        this.memberInformationForm.value.shippingCity !== this.badShippingAddress.city ||
        this.memberInformationForm.value.shippingState !== this.badShippingAddress.state ||
        this.memberInformationForm.value.shippingZipCode !== this.badShippingAddress.zipCode
      ) {
        this.badShippingAddress.isUnknown = false;
        this.cannotReachAddressValidationService = false;
        this.suggestedShippingAddress.address = null;
        this.updateAllAddressValidators(this.memberInformationForm.controls);
      }
    }
  }

  private setValidatorsForAch(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.setValidators(this.routingNumberValidators);
    controls.accountNumber.setValidators(this.accountNumberValidators);
    controls.cardholderName.clearValidators();
    controls.cardNumber.clearValidators();
    controls.expirationDateYear.clearValidators();
    controls.expirationDateMonth.clearValidators();
  }

  private setValidatorsForCreditCard(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.clearValidators();
    controls.accountNumber.clearValidators();
    controls.cardholderName.setValidators(this.cardholderNameValidators);
    controls.cardNumber.setValidators(this.cardNumberValidators);
    controls.expirationDateMonth.setValidators(this.expirationDateMonthValidators);
    controls.expirationDateYear.setValidators(this.expirationDateYearValidators);
  }

  private updateAllPaymentValidators(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.updateValueAndValidity();
    controls.accountNumber.updateValueAndValidity();
    controls.cardholderName.updateValueAndValidity();
    controls.cardNumber.updateValueAndValidity();
    controls.expirationDateMonth.updateValueAndValidity();
    controls.expirationDateYear.updateValueAndValidity();
  }

  private updateAllAddressValidators(controls: { [key: string]: AbstractControl }) {
    controls.address1.updateValueAndValidity();
    controls.city.updateValueAndValidity();
    controls.state.updateValueAndValidity();
    controls.zipCode.updateValueAndValidity();

    controls.shippingAddress1.updateValueAndValidity();
    controls.shippingCity.updateValueAndValidity();
    controls.shippingState.updateValueAndValidity();
    controls.shippingZipCode.updateValueAndValidity();
  }

  private loadCustomerIfReferredFromSearch() {
    const selectedCustomer = this.searchService.getSelectedCustomer();

    if (!this.memberInformationForm.value.address1 && selectedCustomer) {
      if (selectedCustomer.addrLine1) {
        this.memberInformationForm.controls.address1.setValue(selectedCustomer.addrLine1.toUpperCase());
      }

      if (selectedCustomer.addrLine2) {
        this.memberInformationForm.controls.address2.setValue(selectedCustomer.addrLine2.toUpperCase());
      }

      if (selectedCustomer.city) {
        this.memberInformationForm.controls.city.setValue(selectedCustomer.city.toUpperCase());
      }

      this.memberInformationForm.controls.state.setValue(this.abbreviateState(selectedCustomer.state));
      this.memberInformationForm.controls.zipCode.setValue(selectedCustomer.zip);
    }
  }

  private abbreviateState(fullStateName: string): string {
    const matchingState = states.find((state: State) => state.fullName === fullStateName);
    if (matchingState) {
      return matchingState.name;
    } else {
      return fullStateName;
    }
  }
}
