import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPreventCopyPhone]'
})
export class PreventCopyDirective {
  constructor(el: ElementRef, renderer: Renderer2) {
    const events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }
}
