import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFormUppercase]'
})
export class FormUppercaseDirective {
  constructor(private element: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInput(event: any): void {
    const input = event.target as HTMLInputElement;
    const upperCaseText = input.value.toUpperCase();

    if (input.value !== upperCaseText) {
      const userCaretPosition = input.selectionStart;
      const userSelectionEnd = input.selectionEnd;

      this.element.nativeElement.value = upperCaseText;
      this.control.control.setValue(upperCaseText);

      // Prevent the user's cursor from jumping to the end of the field after each keystroke
      input.setSelectionRange(userCaretPosition, userSelectionEnd);
    }
  }
}
