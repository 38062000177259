import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { FormsService } from 'src/app/enrollment-forms/services';

@Component({
  selector: 'app-conversion-landing',
  templateUrl: './conversion-landing.component.html',
  styleUrls: ['./conversion-landing.component.css']
})
export class ConversionLandingComponent implements OnInit {
  @Output() nextEvent = new EventEmitter<number>();

  formsService: FormsService;
  router: Router;

  providerName: string;
  providerPhone: string;
  providerExt: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    formsService: FormsService,
    router: Router
  ) {
    this.formsService = formsService;
    this.router = router;
  }

  ngOnInit() {
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((careDetails: Provider) => {
      if (careDetails) {
        this.providerPhone = careDetails.phoneno;
        this.providerExt = careDetails.ext;
      }
    });
  }

  startConversion(): void {
    this.nextEvent.emit();
  }
}
