import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css']
})
export class PhoneNumberComponent {
  @Input() phoneNumber = '';
  @Input() extension = '';

  constructor() { }

  get hyperlink() {
    const formattedPhoneNumber = this.phoneNumber.replace(/\D/g, '')
      .replace(/^(?![+]?1)/, '1')
      .replace(/^(?![+])/, '+');
    return `tel:${formattedPhoneNumber}`;
  }
}
