import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { EligibilityResponse } from '../model/eligibility.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { HttpErrorService } from 'src/app/error-handler/http-error.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    customersChanged$ = new Subject<EligibilityResponse[]>();
    accountStatus$: BehaviorSubject<string> = new BehaviorSubject(null);

    private customers: EligibilityResponse[];
    private selectedCustomer: EligibilityResponse;

    constructor(
        private http: HttpClient,
        private appConfig: AppConfig,
        private httpErrorService: HttpErrorService,
        private authService: AuthService
    ) { }

    clearCustomerData() {
        this.customers = null;
        this.customersChanged$.next(this.customers);
    }

    getCustomerData(type: string, searchval: string) {
        if (type === 'name') {
            this.getCustomersByName(searchval);
        } else if (type === 'phone') {
            this.getCustomersByPhone(searchval);
        } else if (type === 'mbiMemberNumber') {
            this.getCustomersByMbiMemberNumber(searchval);
        }
    }

    getCustomers(): EligibilityResponse[] {
        return this.customers;
    }

    getSelectedCustomer(): EligibilityResponse {
        return this.selectedCustomer;
    }

    setSelectedCustomer(index: number) {
        this.selectedCustomer = this.customers[index];
    }

    clearSelectedCustomer(): void {
        this.selectedCustomer = null;
    }

    setCustomers(customers: EligibilityResponse[]) {
        this.customers = customers;
        if (this.customers.length === 0) {
            this.customers = null;
        }
        this.customersChanged$.next(this.customers);
    }

    private getCustomersByMbiMemberNumber(mbiMemberNumber: string) {
        return this.getCustomersByApiAndSearchField(this.appConfig.config.enrollmentApiSearchMbi, mbiMemberNumber);
    }

    private getCustomersByName(name: string) {
        return this.getCustomersByApiAndSearchField(this.appConfig.config.enrollmentApiSearchName, name);
    }

    private getCustomersByPhone(phone: string) {
        return this.getCustomersByApiAndSearchField(this.appConfig.config.enrollmentApiSearchPhone, phone);
    }

    private getCustomersByApiAndSearchField(api: string, searchField: string) {
        this.http.get<EligibilityResponse[]>(api + searchField).subscribe(
            (customerList: EligibilityResponse[]) => {
                this.setCustomers(customerList);
            },
            (errorResponse: unknown) => {
                if (errorResponse instanceof HttpErrorResponse) {
                    this.httpErrorService.logHttpErrorDetailsIfDevelopment(errorResponse);
                    if ('status' in errorResponse && (errorResponse.status === 401 ||
                        (errorResponse.status === 400 && errorResponse.error.error &&
                            errorResponse.error.error.includes('Auth cookie is missing')))) {
                        this.clearSelectedCustomer();
                        this.authService.updateLoggedInStatus(false);
                    } if ('status' in errorResponse && errorResponse.status === 404) {
                        this.setCustomers([]);
                    }
                }
            }
        );
    }
}
