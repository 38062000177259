export interface MemberAddress {
  address: string;
  city: string;
  state: string;
  zipCode: string;
  isUnknown: boolean;
  isPoBox: boolean;
}

export const MEMBER_ADDRESS_DEFAULTS: MemberAddress =  {
  address: null,
  city: null,
  state: null,
  zipCode: null,
  isUnknown: false,
  isPoBox: false
};
