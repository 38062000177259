import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VaisModalForms } from 'src/app/shared/models/vais-modal-forms.enum';

@Component({
  selector: 'app-vais-form-modal',
  templateUrl: './vais-form-modal.component.html',
  styleUrls: ['./vais-form-modal.component.css']
})
export class VaisFormModalComponent {
  @Input() formType: VaisModalForms;
  vaisModalForms = VaisModalForms;

  constructor(public formsModal: NgbActiveModal) { }
}
