import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCaptchaModule } from 'ngx-captcha';

import {
  StepperControlComponent, MembershipVerificationComponent,
  SubHeaderComponent, ProductSelectionComponent, MemberInformationComponent,
  CaregiverInfoComponent, ReviewOrderComponent, FormModalComponent, SuccessPageComponent
} from './components';
import { FormatTimePipe } from './components/sub-header/sub-header.component';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [
    StepperControlComponent,
    MembershipVerificationComponent,
    SubHeaderComponent,
    ProductSelectionComponent,
    MemberInformationComponent,
    CaregiverInfoComponent,
    ReviewOrderComponent,
    FormModalComponent,
    SuccessPageComponent,
    FormatTimePipe
  ],
  imports: [
    CommonModule, MaterialModule, NgbModule, ReactiveFormsModule, FormsModule,
    SharedModule, NgSelectModule,
    NgxCaptchaModule
  ],
  providers: [
    TitleCasePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    StepperControlComponent, SubHeaderComponent, MembershipVerificationComponent,
    ProductSelectionComponent, MemberInformationComponent, CaregiverInfoComponent,
    FormModalComponent, SuccessPageComponent
  ],
  entryComponents: [FormModalComponent]
})

export class EnrollmentFormsModule { }
