import { Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';
import { FormsService } from 'src/app/enrollment-forms/services';

@Directive({
  selector: '[appClickOutside]'
})

export class ClickOutsideDirective {
  @Output() clickOutside: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef, private formsService: FormsService) { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.formsService.showSaveButton$.next(true);
      this.clickOutside.emit(null);
    } else {
      this.formsService.showSaveButton$.next(false);
    }
  }
}
