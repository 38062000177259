import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Account, Address } from '../models/account.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { PlatformAccountPayload } from '../models/platform-account-payload.model';
import { PlatformContactAddress } from '../models/platform-contact-address.model';
import { PlatformContactPhone } from '../models/platform-contact-phone.model';
import { PlatformEmergencyContactBase } from '../models/platform-emergency-contact-base.model';
import { PlatformEmergencyContactRole } from '../models/platform-emergency-contact-role.enum';
import { getPlatformGenderOption, PlatformGenderOptions } from '../models/platform-gender-options.enum';
import { getPlatformRelationshipType, PlatformRelationshipType } from '../models/platform-relationship-type.enum';
import { UserData, Verify } from '../models/user.model';
import { PlatformCreateAccountWithPaymentResponse } from '../models/platform-create-account-with-payment-response.model';
import { PlatformSubscriberPaymentRequest } from 'src/app/conversions/models/platform-subscriber-payment-request';
import { PlatformCreateAccountWithoutPaymentResponse } from '../models/platform-create-account-without-payment-response.model';
import { delay } from 'rxjs/operators';

const PLATFORM_PRIMARY_CONTACT_ADDRESS_STATUS = 'PI';
const EMERGENCY_CONTACT_SEQUENCE_BASE = 20;
const EMERGENCY_CONTACT_SEQUENCE_MULTIPLIER = 10;
const DIRECT_TO_CONSUMER = 'RESDTC';

@Injectable({
  providedIn: 'root'
})
export class PlatformApiService {
  verificationData: Verify;

  constructor(
    private appConfig: AppConfig,
    private httpClient: HttpClient
  ) { }

  createAccount$(accounts: Account[]): Observable<any> {
    const accountHolder: Account = accounts.find(a => a.isCareRecipient);
    const emergencyContacts: Account[] = accounts.filter(a => !a.isCareRecipient);
    const userVerificationData: UserData = this.verificationData.users[0];

    const payloadAccount: PlatformAccountPayload = {
      Agency: null,
      BusinessUnit: 'Lifeline',
      Account: {
        CentralStation: {
          Template: '1',
          SyncPortal: 'Mastermind',
          SiteGroup: null
        },
        Contacts: {
          AccountHolders: {
            Primary: {
              Language: accountHolder.language,
              Gender: PlatformGenderOptions[accountHolder.gender] && PlatformGenderOptions[accountHolder.gender].toString(),
              FirstName: accountHolder.firstName,
              MiddleName: accountHolder.middleName,
              LastName: accountHolder.lastName,
              DateOfBirth: accountHolder.dateOfBirth ? new Date(accountHolder.dateOfBirth) : null,
              Contact: {
                Addresses: this.uniqueAddresses(accountHolder.addresses.map((a: Address): PlatformContactAddress => ({
                  AddressType: userVerificationData.siteType_Id,
                  AddressStatus: a.addressType === 'Home' ? PLATFORM_PRIMARY_CONTACT_ADDRESS_STATUS : null,
                  ServiceAreaId: parseInt(userVerificationData.serveArea_Id, 10),
                  Line1: a.line1,
                  Line2: a.line2,
                  City: a.city,
                  State: a.state,
                  PostalCode: a.zip,
                  County: a.county,
                  Country: a.country
                }))),
                Email: accountHolder.emails && accountHolder.emails[0] && accountHolder.emails[0].address || null,
                PhoneNumbers: accountHolder.phones && accountHolder.phones.map((p): PlatformContactPhone => ({
                  PhoneNumber: this.removeNonNumeric(p.phoneNumber),
                  Type: p.type,
                  Sms: (p.type === 'Cell')
                })) || null
              },
              EmergencyInformation: {
                Dispatch: {
                  LockBoxLocation: accountHolder.locationOfHouseKey
                }
              }
            }
          },
          EmergencyContacts: emergencyContacts.map((e, i): PlatformEmergencyContactBase => ({
            Sequence: EMERGENCY_CONTACT_SEQUENCE_BASE + i * EMERGENCY_CONTACT_SEQUENCE_MULTIPLIER,
            Language: e.language,
            Gender: PlatformGenderOptions[e.gender] && PlatformGenderOptions[e.gender].toString(),
            FirstName: e.firstName,
            MiddleName: e.middleName,
            LastName: e.lastName,
            DateOfBirth: e.dateOfBirth ? new Date(e.dateOfBirth) : null,
            RelationshipType: PlatformRelationshipType[e.relationship] && PlatformRelationshipType[e.relationship].toString(),
            Roles: [
              e.isAwayContact ? PlatformEmergencyContactRole.AwayContact.toString() : null,
              e.isNotifier ? PlatformEmergencyContactRole.Notify.toString() : null,
              e.isResponder ? PlatformEmergencyContactRole.Responder.toString() : null
            ].filter(x => x != null) || null,
            Contact: {
              Email: e.emails && e.emails[0] && e.emails[0].address || null,
              PhoneNumbers: e.phones && e.phones.map((p): PlatformContactPhone => ({
                PhoneNumber: this.removeNonNumeric(p.phoneNumber),
                Type: p.type,
                Sms: (p.type === 'Cell')
              })) || null,
              RequiresTTY: e.requiresTTY,
            }
          }))
        }
      },
      HcdProgramName: userVerificationData.custNo,
      Affiliation: {
        MemberId: userVerificationData.membershipNumber,
        GroupNumber: userVerificationData.groupNo,
        GroupId: userVerificationData.group_id,
        MBIMedicaidId: userVerificationData.identity,
        WaiverFundingSource: null,
        ServiceCompanyId: userVerificationData.servco_no,
        StartDate: userVerificationData.effDate,
        EndDate: userVerificationData.expiryDate,
        MemberExpiration: userVerificationData.expiryDate
      },
      ProductConfiguration: {
        KitIds: accountHolder.jobNumbers
      },
      AdditionalParameters: {
      }
    };

    const payload: PlatformAccountPayload[] = [payloadAccount];

    if (this.appConfig.config.debug) {
      console.log('Calling Platform API with payload:');
      console.log(JSON.stringify(payload));
    }

    return this.httpClient.post<any>(this.appConfig.config.platformApiAccounts, payload, this.platformApiOptions());
  }

  createAccountWithoutPayment$(accounts: Account[], ServiceCompanyId: string):
    Observable<PlatformCreateAccountWithoutPaymentResponse> {
      const payload = this.createAccountWithPotentialPaymentPayload(accounts, null, ServiceCompanyId);

    if (this.appConfig.config.fakePlatformCalls) {
      console.log('Faking submission', payload);
      return of({siteNumber: '123456789'}).pipe(delay(3000));
    }

    if (this.appConfig.config.debug) {
      console.log('Calling Platform API via Enrollment API with payload:');
      console.log(JSON.stringify(payload));
    }

    return this.httpClient.post<PlatformCreateAccountWithoutPaymentResponse>(this.appConfig.config.enrollmentApiEnrollWithoutPayment,
      payload, this.enrollmentApiOptions());
  }

  createAccountWithPayment$(accounts: Account[], paymentInformation: PlatformSubscriberPaymentRequest, ServiceCompanyId: string):
    Observable<PlatformCreateAccountWithPaymentResponse> {
    const payload = this.createAccountWithPotentialPaymentPayload(accounts, paymentInformation, ServiceCompanyId);

    if (this.appConfig.config.fakePlatformCalls) {
      console.log('Faking submission', payload);
      return of({siteNumber: '123456789'}).pipe(delay(3000));
    }

    if (this.appConfig.config.debug) {
      console.log('Calling Platform API via Enrollment API with payload:');
      console.log(JSON.stringify(payload));
    }

    return this.httpClient.post<PlatformCreateAccountWithPaymentResponse>(this.appConfig.config.enrollmentApiEnrollWithPayment,
      payload, this.enrollmentApiOptions());
  }

  private createAccountWithPotentialPaymentPayload(accounts: Account[],
    paymentInformation: PlatformSubscriberPaymentRequest,
    ServiceCompanyId: string): PlatformAccountPayload {
    const accountHolder: Account = accounts.find(a => a.isCareRecipient && !a.isShippingInformation);
    const shippingInformation: Account = accounts.find(a => a.isCareRecipient && a.isShippingInformation);
    const emergencyContacts: Account[] = accounts.filter(a => !a.isCareRecipient && !a.isShippingInformation);

    const payload: PlatformAccountPayload = {
      Agency: null,
      BusinessUnit: 'Lifeline',
      Account: {
        CentralStation: {
          Template: '1',
          SyncPortal: 'Mastermind',
          SiteGroup: null
        },
        Contacts: {
          AccountHolders: {
            Primary: {
              Language: accountHolder.language,
              Gender: getPlatformGenderOption(accountHolder.gender),
              FirstName: accountHolder.firstName,
              MiddleName: accountHolder.middleName,
              LastName: accountHolder.lastName,
              DateOfBirth: accountHolder.dateOfBirth ? new Date(accountHolder.dateOfBirth) : null,
              Contact: {
                Addresses: this.uniqueAddresses(accountHolder.addresses.map((a: Address): PlatformContactAddress => ({
                  AddressType: DIRECT_TO_CONSUMER,
                  AddressStatus: a.addressType === 'Home' ? PLATFORM_PRIMARY_CONTACT_ADDRESS_STATUS : null,
                  Line1: a.line1,
                  Line2: a.line2,
                  City: a.city,
                  State: a.state,
                  PostalCode: a.zip,
                  County: a.county,
                  Country: a.country
                }))),
                Email: accountHolder.emails && accountHolder.emails[0] && accountHolder.emails[0].address || null,
                PhoneNumbers: accountHolder.phones && accountHolder.phones.map((p): PlatformContactPhone => ({
                  PhoneNumber: this.removeNonNumeric(p.phoneNumber),
                  Type: p.type,
                  Sms: (p.type === 'Cell')
                })) || null
              },
              EmergencyInformation: {
                Dispatch: {
                  LockBoxLocation: accountHolder.locationOfHouseKey
                }
              }
            },
            Shipping: {
              FirstName: shippingInformation.firstName,
              MiddleName: shippingInformation.middleName,
              LastName: shippingInformation.lastName,
              Contact: {
                Addresses: this.uniqueAddresses(shippingInformation.addresses.map((a: Address): PlatformContactAddress => ({
                  Line1: a.line1,
                  Line2: a.line2,
                  City: a.city,
                  State: a.state,
                  PostalCode: a.zip,
                  County: a.county,
                  Country: a.country
                }))),
                Email: shippingInformation.emails && shippingInformation.emails[0] && shippingInformation.emails[0].address || null,
                PhoneNumbers: shippingInformation.phones && shippingInformation.phones.map((p): PlatformContactPhone => ({
                  PhoneNumber: this.removeNonNumeric(p.phoneNumber),
                  Type: p.type,
                  Sms: (p.type === 'Cell')
                })) || null
              }
            }
          },
          EmergencyContacts: emergencyContacts.map((e, i): PlatformEmergencyContactBase => ({
            Sequence: EMERGENCY_CONTACT_SEQUENCE_BASE + i * EMERGENCY_CONTACT_SEQUENCE_MULTIPLIER,
            Language: e.language,
            Gender: getPlatformGenderOption(e.gender),
            FirstName: e.firstName,
            MiddleName: e.middleName,
            LastName: e.lastName,
            DateOfBirth: e.dateOfBirth ? new Date(e.dateOfBirth) : null,
            RelationshipType: getPlatformRelationshipType(e.relationship),
            Roles: [
              e.isAwayContact ? PlatformEmergencyContactRole.AwayContact.toString() : null,
              e.isNotifier ? PlatformEmergencyContactRole.Notify.toString() : null,
              e.isResponder ? PlatformEmergencyContactRole.Responder.toString() : null
            ].filter(x => x != null) || null,
            Contact: {
              Email: e.emails && e.emails[0] && e.emails[0].address || null,
              PhoneNumbers: e.phones && e.phones.map((p): PlatformContactPhone => ({
                PhoneNumber: this.removeNonNumeric(p.phoneNumber),
                Type: p.type,
                Sms: (p.type === 'Cell')
              })) || null,
              RequiresTTY: e.requiresTTY,
            }
          }))
        }
      },
      Affiliation: {
        ServiceCompanyId
      },
      ProductConfiguration: {
        KitIds: accountHolder.jobNumbers
      },
      AdditionalParameters: {
      }
    };

    if (paymentInformation) {
      payload.payment = paymentInformation;
    }

    return payload;
  }

  private platformApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.verificationData.accessToken}`)
        .set('Content-Type', 'application/json')
    };
  }

  private enrollmentApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('AppId', this.appConfig.config.enrollmentAppId)
        .set('AppSecret', this.appConfig.config.enrollmentAppSecret)
        .set('Content-Type', 'application/json')
    };
  }

  private removeNonNumeric(mixedString: string): string {
    return mixedString.replace(/[^0-9]/g, '');
  }

  private uniqueAddresses(addresses: PlatformContactAddress[]): PlatformContactAddress[] {
    return addresses.filter((v, i, a) => i === 0 ||
      v.Line1 !== a[i - 1].Line1 ||
      v.Line2 !== a[i - 1].Line2 ||
      v.City !== a[i - 1].City ||
      v.State !== a[i - 1].State ||
      v.PostalCode !== a[i - 1].PostalCode ||
      v.County !== a[i - 1].County ||
      v.Country !== a[i - 1].Country
    );
  }
}
