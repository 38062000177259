import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AppConfig } from '../shared/services/app-config.service';

@Component({
  selector: 'app-aws-rum',
  templateUrl: './aws-rum.component.html',
  styleUrls: ['./aws-rum.component.css']
})
export class AwsRumComponent implements OnInit, AfterViewInit {
  awsRumApplicationId: string;
  awsRumGuestRoleArn: string;
  awsRumIdentityPoolId: string;

  constructor(
    private appConfig: AppConfig,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.awsRumApplicationId = this.appConfig.config.awsRumApplicationId;
    this.awsRumGuestRoleArn = this.appConfig.config.awsRumGuestRoleArn;
    this.awsRumIdentityPoolId = this.appConfig.config.awsRumIdentityPoolId;
  }

  ngAfterViewInit() {
    if (this.awsRumApplicationId &&
      this.awsRumGuestRoleArn &&
      this.awsRumIdentityPoolId
    ) {
      this.addAwsRumScript();
    } else {
      if (this.appConfig.config.debug) {
        if (!this.awsRumApplicationId) {
          console.error('AWS RUM application ID not found in configuration');
        }

        if (!this.awsRumGuestRoleArn) {
          console.error('AWS RUM guest role ARN not found in configuration');
        }

        if (!this.awsRumIdentityPoolId) {
          console.error('AWS RUM identity pool ID not found in configuration');
        }
      }
    }
  }

  private addAwsRumScript(): void {
    const script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `(function (n, i, v, r, s, c, u, x, z) {
      x = window.AwsRumClient = { q: [], n: n, i: i, v: v, r: r, c: c, u: u };
      window[n] = function (c, p) { x.q.push({ c: c, p: p }); };
      z = document.createElement('script');
      z.async = true;
      z.src = s;
      document.getElementsByTagName('script')[0].parentNode.insertBefore(z, document.getElementsByTagName('script')[0]);
    })(
      'cwr',
      '${this.awsRumApplicationId}',
      '1.0.0',
      'us-east-1',
      'https://client.rum.us-east-1.amazonaws.com/1.0.2/cwr.js',
      {
        sessionSampleRate: 1,
        guestRoleArn: '${this.awsRumGuestRoleArn}',
        identityPoolId: '${this.awsRumIdentityPoolId}',
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        telemetries: ['errors', 'http', 'performance'],
        allowCookies: true
      });`;
    this.elementRef.nativeElement.appendChild(script);
  }
}
