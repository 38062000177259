import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { PayerConfig } from '../model/payerconfig.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class PayerConfigService {
  currentState$: Observable<boolean>;

  private isLoggedIn$ = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient, private appConfig: AppConfig) {
    this.currentState$ = this.isLoggedIn$.asObservable();
  }

  savePayerConfig(payerconfig: PayerConfig) {
    const httpOptions = this.addressApiOptions();
    return this.httpClient.post(`${this.appConfig.config.addressApiPayerconfig}`, payerconfig, httpOptions);
  }

  changeLoggedInState(message: boolean) {
    this.isLoggedIn$.next(message);
  }

  private addressApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer a7d22ad9-d0a5-47cb-8180-6ef6c2805f0e`)
        .set('Content-Type', 'application/json')
    };
  }
}
