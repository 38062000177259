import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { FormsService } from 'src/app/enrollment-forms/services';

@Component({
  selector: 'app-select-provider',
  templateUrl: './select-provider.component.html',
  styleUrls: ['./select-provider.component.css']
})
export class SelectProviderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  providers: Provider[];
  providerControl: FormControl = new FormControl();
  providerFilterControl: FormControl = new FormControl();
  filteredProviders$: ReplaySubject<Provider[]> = new ReplaySubject<Provider[]>(1);
  protected destroy$ = new Subject<void>();

  constructor(private formsService: FormsService) { }

  ngOnInit() {
    this.providers = this.formsService.getCurrentProviders();
    if (this.providers.length > 1) {
      this.filteredProviders$.next(this.providers.slice());
      // set default selection
      this.providerControl.setValue(this.providers[0]);

      this.providerFilterControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.filterProviders();
        });
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updatedSelectedProvider(provider: Provider) {
    this.formsService.setSelectedProvider(provider);
  }

  protected setInitialValue() {
    this.filteredProviders$
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: Provider, b: Provider) => {
            if (a.planname && b.planname) {
              return a.planname === b.planname;
            } else {
              return a.carename === b.carename;
            }
          };
        }
      });
  }

  protected filterProviders() {
    if (!this.providers) {
      return;
    }
    let search = this.providerFilterControl.value;
    if (!search) {
      this.filteredProviders$.next(this.providers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredProviders$.next(
      this.providers.filter((provider: Provider) => {
        let result = false;
        if (provider.hasOwnProperty('planname')) {
          result = provider.planname.toLowerCase().indexOf(search) > -1;
        } else if (provider.hasOwnProperty('carename')) {
          result = provider.carename.toLowerCase().indexOf(search) > -1;
        }
        return result;
      })
    );
  }
}
