/* eslint-disable @typescript-eslint/naming-convention -- Must match format expected by Platform API */
export enum PlatformEmergencyContactRole {
    Admin = 'ADM',
    AwayContact = 'AWC',
    Notify = 'NOK',
    Responder = 'RES'
}

/* The following are valid values that are not currently used by SSEP.
   They are noted here in case they are required in the future.

    AwayContactAndAdmin = 'ALL',
    Caregiver = 'CAR',
    ProgramManager = 'LPM',
    PrimaryCarePhysician = 'PCP',
    ResponderNotify = 'RNT',
    Subscriber = 'SUB',
    ThirdPartyNotify = 'TPN'
*/
