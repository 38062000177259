import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appPhoneFormat]'
})

export class PhoneFormatDirective {
  private _phoneControl: AbstractControl;

  constructor(private elementRef: ElementRef) { }

  @Input()
  set phoneControl(control: AbstractControl) {
    this._phoneControl = control;
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(value: any) {
    if (value !== null) {
      this.onInputChange(value);
    }
  }

  // Phone number value is masked in the format XXX-XXX-XXXX
  onInputChange(value: string) {
    let start = this.elementRef.nativeElement.selectionStart;
    const end = this.elementRef.nativeElement.selectionEnd;
    let newVal = value.replace(/\D/g, '');

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    } else {
      newVal = newVal.substring(0, 9);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    }

    if (value !== newVal) {
      this._phoneControl.setValue(newVal, { emitEvent: false });
    }

    if ((value.length < newVal.length && start === end) || ((start === 4) || start === 8) && value.length !== newVal.length) {
      if (start > 3) {
        start = start + 1;
      }
      if (start > 7) {
        start = start + 1;
      }
    }
    this.elementRef.nativeElement.setSelectionRange(start, start);
  }
}
