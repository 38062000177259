import gender from '../../../assets/json/gender.json';

/* eslint-disable @typescript-eslint/naming-convention -- Must match format expected by Platform API */
export enum PlatformGenderOptions {
    Unknown = 'Unknown',
    Female = 'Female',
    Male = 'Male',
    Other = 'Other',
    DeclinedToSpecify = 'Declined to Specify'
}

export const getPlatformGenderOption = (genderString: string): PlatformGenderOptions => {
    if (!genderString) {
        return null;
    }

    const selectedGender = gender.find(genderOption => genderOption.name.toUpperCase() === genderString.toUpperCase());
    const platformGenderString = selectedGender ? (selectedGender.platformAlias || selectedGender.name) : genderString;
    return PlatformGenderOptions[platformGenderString] ||
        Object.values(PlatformGenderOptions).find((genderOption) =>
            genderOption.toUpperCase() === platformGenderString.toUpperCase());
};
