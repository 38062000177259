import { Component, HostListener } from '@angular/core';

@Component({
    template: ''
})
export abstract class ComponentCanDeactivateComponent {
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }

    abstract canDeactivate(): boolean;
}
