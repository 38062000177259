import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { AppConfig } from 'src/app/shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class TraceIdService {
  private id: Guid;

  constructor(private appConfig: AppConfig) {
    this.generateNewId();
  }

  getExistingId(): string {
    return this.id.toString();
  }

  generateNewId(): string {
    this.id = Guid.create();

    if (this.appConfig.config.debug) {
      console.log(`Generated trace ID ${this.id.toString()}`);
    }

    return this.id.toString();
  }
}
