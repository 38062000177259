import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SearchService } from '../eligibility-search/service/search.service';
import { Provider } from '../enrollment-forms/models/provider.model';
import { FormsService } from '../enrollment-forms/services';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})

export class AppHeaderComponent implements OnInit {
  showDateTime = false;
  careType: string;
  providerPhone: string;
  providerExt: string;
  isConfigManager: boolean;
  isloggedIn = false;

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private formsService: FormsService,
    private searchService: SearchService
  ) {
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isLoggedIn => {
      this.isloggedIn = isLoggedIn;
    });

    // Check for care type
    this.formsService.careDetails$.subscribe((careDetails: Provider) => {
      if (careDetails) {
        this.providerPhone = careDetails.phoneno;
        this.providerExt = careDetails.ext;
        this.changeDetectorRef.detectChanges();
      } else {
        this.providerPhone = '';
        this.providerExt = '';
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  logout() {
    this.searchService.clearSelectedCustomer();
    this.authService.logout();
  }
}
