import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../models/product.model';
import { ProductModal } from '../../models/product-modal.model';
import { ProductReplacement } from '../../models/product-replacement.model';
import { FormsService } from 'src/app/enrollment-forms/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() modalTitle: string;
  @Input() modalContent: string;
  @Input() showDismiss: boolean; // Close Icon
  @Input() showHeader: boolean;
  @Input() showFooter: boolean;
  @Input() modalImage: any;
  @Input() showFormComponent: boolean;
  @Input() formType: number;
  @Input() isProdModal: boolean;
  @Input() prodData: Product;
  @Input() modalData: ProductModal;
  @Input() replacement: ProductReplacement;
  @Input() isCell: boolean;
  @Input() isReplace: boolean;
  @Input() prodImage: string;
  @Input() prodID: number;
  @Input() isExitModal: boolean;

  @Output() closeReplacement = new EventEmitter<number>();
  @Output() selectReplacement = new EventEmitter<any>();
  @Output() backProduct = new EventEmitter();
  @Output() exit = new EventEmitter();
  @Output() closeReplace = new EventEmitter();
  @Output() closeExit = new EventEmitter();

  modalPhoneNo: string;
  modalExt: string;
  isMobileModal: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public activeModal: NgbActiveModal, private formsService: FormsService) {
    this.showDismiss = false;
    this.showFooter = false;
    this.showHeader = false;
    this.showFormComponent = false;
    this.isProdModal = false;
    this.isExitModal = false;
    this.isMobileModal = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe(modalCareType => {
      if (modalCareType) {
        this.modalPhoneNo = modalCareType.phoneno;
        this.modalExt = modalCareType.ext;
        if (this.modalData && this.modalData.title && this.modalData.title.includes('On the Go')) {
          this.isMobileModal = true;
        }
      }
    });
  }

  selectProductReplacement(replacementProd: ProductReplacement, idNo: number) {
    this.selectReplacement.emit({ replacementProd, idNo });
  }

  closeReplacementModal(prodId: number) {
    this.closeReplacement.emit(prodId);
  }

  backToProduct() {
    this.backProduct.emit();
  }

  exitApp() {
    this.exit.emit();
  }

  closeModalReplace() {
    this.closeReplace.emit();
  }

  closeModalExit() {
    this.closeExit.emit();
  }
}
