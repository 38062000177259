import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor(
    private appConfig: AppConfig
  ) { }

  public logHttpErrorDetailsIfDevelopment(httpError: HttpErrorResponse, originalRequestPayload?: any) {
    if (this.appConfig.config.debug) {
      console.error('HTTP error. See verbose/log logs for details.');
      console.log(`URL: ${httpError.url}`);

      if (originalRequestPayload) {
        if (typeof originalRequestPayload === 'string' || originalRequestPayload instanceof String) {
          console.log(`Payload sent: ${originalRequestPayload}`);
        } else {
          console.log(`Payload sent: ${JSON.stringify(originalRequestPayload)}`);
        }
      }

      console.log(`Status code: ${httpError.status}`);
      console.log(`Type: ${httpError.type}`);
      console.log(`Message: ${httpError.message}`);

      if (httpError.error) {
        if (typeof httpError.error === 'string' || httpError.error instanceof String) {
          console.log(`Payload received: ${httpError.error}`);
        } else {
          console.log(`Payload received: ${JSON.stringify(httpError.error)}`);
        }
      } else {
        console.log(`No response payload present`);
      }
    }
  }
}
