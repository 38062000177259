import { Component, OnInit } from '@angular/core';
import { VaisFormsService } from '../../services';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';

@Component({
  selector: 'app-vais-provider-phone',
  templateUrl: './vais-provider-phone.component.html',
  styleUrls: ['./vais-provider-phone.component.css']
})
export class VaisProviderPhoneComponent implements OnInit {
  provider: Provider;

  constructor(private vaisFormsService: VaisFormsService) { }

  ngOnInit() {
    this.provider = this.vaisFormsService.provider;
  }
}
