import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { VaisFormsService } from '../../services';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';

@Component({
  selector: 'app-vai-slanding-page',
  templateUrl: './vais-landing-page.component.html',
  styleUrls: ['./vais-landing-page.component.css']
})

export class VaisLandingPageComponent {
  isFireFox: boolean;
  isSafari: boolean;
  isIEorEdge: boolean;
  isChrome: boolean;
  isOpera: boolean;
  message: string;
  isEnrollmentDisabled = false;
  provider: Provider;

  constructor(
    private router: Router,
    private searchService: SearchService,
    private vaisFormsService: VaisFormsService
  ) {
    this.isChrome = /Chrome/.test(navigator.userAgent) &&
      !/Chromium/.test(navigator.userAgent) &&
      !/Edg/i.test(navigator.userAgent);
    this.isIEorEdge = /msie\s|trident\/|edge\//.test(navigator.userAgent);
    this.isFireFox = /Firefox/.test(navigator.userAgent) &&
      !/Seamonkey/.test(navigator.userAgent);
    this.isOpera = /Opera|OPR/.test(navigator.userAgent);
    this.isSafari = /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent);

    this.searchService.clearSelectedCustomer();
    this.provider = this.vaisFormsService.provider;
  }

  redirectToEnrollmentForms(): void {
    this.router.navigateByUrl(`${this.router.url}/enrollmentforms`);
  }
}
