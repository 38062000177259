// CloudWatch RUM
// See https://github.com/aws-observability/aws-rum-web/blob/main/docs/cdn_angular.md
declare function cwr(operation: string, payload: any): void;

export class AwsRumFunctions {
    constructor() { }

    public static callCwr(operation: string, payload: any) {
        if (typeof cwr === 'function') {
            cwr(operation, payload);
        }
    }
}
