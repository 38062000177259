import { Injectable, ErrorHandler } from '@angular/core';

import { AppConfig } from '../shared/services/app-config.service';
import { AwsRumFunctions } from '../shared/aws-rum-functions.service';

@Injectable()
export class CustomErrorService implements ErrorHandler {
  constructor(private appConfig: AppConfig) { }

  handleError(error: any) {
    AwsRumFunctions.callCwr('recordError', error);

    if (this.appConfig.config.debug) {
      console.error(error);
    }
  }
}
