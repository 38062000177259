import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AwsRumComponent } from './aws-rum/aws-rum.component';
import { CanDeactivateGuard } from './authguard/can-deactivate.guard';
import { ConfigManagerComponent } from './config-manager/config-manager.component';
import { ConfigManagerLoginComponent } from './config-manager-login/config-manager-login.component';
import { DefaultMessageComponent } from './default-message/default-message.component';
import { SearchFieldComponent } from './eligibility-search/components/searchfield/searchfield.component';
import { SearchResultsComponent } from './eligibility-search/components/searchresults/searchresults.component';
import { EligibilitySearchComponent } from './eligibility-search/eligibility-search.component';
import { EnrollmentFormsModule } from './enrollment-forms';
import { CustomErrorService } from './error-handler/custom-error.service';
import { HttpResponseInterceptor } from './interceptors/http-response.interceptor';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared';
import { AppConfig, initConfig } from './shared/services/app-config.service';
import { ConversionStepperControlComponent }
  from './conversions/components/conversion-stepper-control/conversion-stepper-control.component';
import { ConversionVerificationComponent } from './conversions/components/conversion-verification/conversion-verification.component';
import { ConversionPaymentComponent } from './conversions/components/conversion-payment/conversion-payment.component';
import { ConversionFinalizeComponent } from './conversions/components/conversion-finalize/conversion-finalize.component';
import { ConversionLandingComponent } from './conversions/components/conversion-landing/conversion-landing.component';
import { VaisEnrollmentFormsModule } from './vais';
import { VaisLandingPageComponent } from './vais/components/vais-landing-page/vais-landing-page.component';

@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AwsRumComponent,
    ConfigManagerComponent,
    ConfigManagerLoginComponent,
    DefaultMessageComponent,
    EligibilitySearchComponent,
    LandingPageComponent,
    SearchFieldComponent,
    SearchResultsComponent,
    ConversionStepperControlComponent,
    ConversionLandingComponent,
    ConversionVerificationComponent,
    ConversionPaymentComponent,
    ConversionFinalizeComponent,
    VaisLandingPageComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    EnrollmentFormsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MaterialModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    VaisEnrollmentFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/ssep'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorService
    },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfig],
      useFactory: initConfig
    },
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
