import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vais-sub-header',
  templateUrl: './vais-sub-header.component.html',
  styleUrls: ['./vais-sub-header.component.css']
})

export class VaisSubHeaderComponent {
  @Input() headerText: string | undefined;
}
