import { ProductModal } from './product-modal.model';
import { ProductReplacement } from './product-replacement.model';

export interface Product {
    id?: number;
    deviceType?: string;
    prodNo: string;
    bundleNo: string;
    prodImage: string;
    prodTitle: string;
    prodDescription: string;
    prodMonthlyRate: number;
    jobNo?: string;
    alternateJobNos?: AlternateJobNo[];
    initialCost?: number;
    fallDetectionIncluded?: boolean;
    fallDetectionPrice?: number;
    billCode?: string;
    additionalSubscriberPrice?: number;
    additionalSubscriberBillCode?: string;
    hasAwayService?: boolean;
    modal?: ProductModal[];
    stock?: boolean;
    prodReplacement?: ProductReplacement[];
}

interface AlternateJobNo {
    jobNo: string;
    withFallDetection?: boolean;
    withInstallation?: boolean;
}

export const PRODUCT_DEFAULTS: Product = {
    prodDescription: null,
    prodImage: null,
    prodMonthlyRate: null,
    bundleNo: null,
    prodTitle: null,
    prodNo: null
};
