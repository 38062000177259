import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-page',
  templateUrl: './browser-page.component.html',
  styleUrls: ['./browser-page.component.css']
})
export class BrowserPageComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
