import { Router } from '@angular/router';
import { EligibilityResponse } from '../../model/eligibility.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-results',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.css']
})

export class SearchResultsComponent implements OnInit, OnDestroy {
  customers: EligibilityResponse[];
  subscription: Subscription;
  selectedCustomer: EligibilityResponse;

  constructor(
    private searchService: SearchService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.subscription = this.searchService.customersChanged$
      .subscribe(
        (customerList: EligibilityResponse[]) => {
          this.customers = customerList;
        }
      );
    this.selectedCustomer = this.searchService.getSelectedCustomer();
  }

  rowClicked(index: number) {
    this.searchService.setSelectedCustomer(index);
    this.selectedCustomer = this.searchService.getSelectedCustomer();

    let providerName = '';

    if (this.selectedCustomer.providerName) {
      providerName = this.selectedCustomer.providerName.toLowerCase();
    }

    if (providerName.includes('humana')) {
      if (providerName.includes('gr')) {
        this.router.navigateByUrl('humanagr/enrollmentforms');
      } else {
        this.router.navigateByUrl('humana/enrollmentforms');
      }
    } else if (providerName.includes('united') ||
      providerName.includes('uhc')) {
      if (providerName.includes('gr')) {
        this.router.navigateByUrl('uhcgr/enrollmentforms');
      } else {
        this.router.navigateByUrl('uhc/enrollmentforms');
      }
    } else {
      window.alert('Unknown provider');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
